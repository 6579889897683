import React from 'react';
import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/images/error.jpg"
import "./_NotFound.scss"
import {
    Box,
    Button,
    Container,
    Typography
  } from "@mui/material";

  const NotFound: React.FC = () => {
    const navigate = useNavigate();
    
  return (
    <Container>
        <Box className={"not_found"}>
            <img style={{ width: '300px', height: '290px' }} src={errorImg} alt="" />
            <Typography sx={{marginBottom: "10px"}} variant="h3" fontWeight="bold">Page not found</Typography>
            <Button style={{
                backgroundColor: "#C54B3A",
                color: "white",
            }} onClick={() => navigate("/")}>Return to home page</Button>
        </Box>
    </Container>
  )
}
export default NotFound