import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Container } from "@mui/material";
import { useState } from "react";
import AnnualOddsImg from "../../assets/images/annual_odds.jpg"

export default function FAQuestion() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box component="section" maxWidth="xl" className="faq-accordion">
        <Container
          sx={{
            padding: { xs: 2, sm: 3, md: 4 },
          }}
          maxWidth="lg"
        >
          <Typography
            variant="h4"
            sx={{ marginBottom: 4 }}
            fontWeight="bold"
          >
            FAQ
          </Typography>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel1"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={600}>What is a Green Card?</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                A Green Card, officially called a Permanent Resident Card, lets
                you live and work permanently in the United States. Here’s what
                it means:
              </Typography>
              <ul>
                <li>
                  <b>Permanent Residency</b>: You can live in the U.S.
                  permanently.
                </li>
                <li>
                  <b>Work Authorization</b>: You can work in the U.S. without
                  needing a specific employer to sponsor you.
                </li>
                <li>
                  <b>Travel</b>: You can travel in and out of the U.S., but long
                  absences might affect your residency.
                </li>
                <li>
                  <b>Path to Citizenship</b>: After having a Green Card for five
                  years (or three years if married to a U.S. citizen), you can
                  apply for U.S. citizenship.
                </li>
                <li>
                  <b>Rights and Responsibilities</b>: You have many rights
                  similar to U.S. citizens but can't vote in federal elections.
                </li>
              </ul>
              <Typography>
                You can get a Green Card through family, a job, refugee or
                asylum status, or the Diversity Visa Lottery.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel2"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography fontWeight={600}>
                What is a Green Card Lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                The Green Card Lottery, officially known as the Diversity Visa
                (DV) Lottery, is run by the U.S. Department of State. It offers
                up to 55,000 Green Cards each year to people from countries with
                low immigration rates to the U.S. The aim is to increase
                diversity in the U.S. by selecting applicants from various
                countries.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel3"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography fontWeight={600}>
                What is the Difference Between the Green Card Lottery and the Diversity Visa Lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                There is no difference. The Green Card Lottery and the Diversity Visa (DV) Lottery are the different names for the same program. The Green Card Lottery (officially Diversity Visa (DV) Lottery) program offers a chance for people from countries with low immigration rates to apply for a Green Card and live and work in the U.S.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel4"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <Typography fontWeight={600}>
                Can I apply for the Green Card lottery for free?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Yes, you can apply for the DV Lottery for free through the official U.S. Department of State website during the designated registration period each year. The U.S. government does not charge any fees to enter the DV Lottery.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel5"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5-content"
              id="panel5-header"
            >
              <Typography fontWeight={600}>
                What services does Immigrateable.com offer?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Immigrateable.com helps with the Diversity Visa (DV) Lottery application process. We offer:
              </Typography>
              <ul>
                <li>
                  <b>Application Review and Submission</b>: We make sure your application is correct and submitted on time.
                </li>
                <li>
                  <b>Photo Validation</b>: We check your photo to meet DV Lottery requirements.
                </li>
                <li>
                  <b>Year-Round Submission</b>: Submit your information anytime, and we ensure it's submitted when the application window opens.
                </li>
                <li>
                  <b>Automatic Yearly Resubmission</b>: If you don't win, we resubmit your application each year.
                </li>
                <li>
                  <b>Embassy Interview Preparation</b>: If you win, we help you prepare for your interview.
                </li>
                <li>
                  <b>Green Card Application Assistance</b>: We help winners with their Green Card application for free, valued at $1000.
                </li>
                <li>
                  <b>Personalized Support</b>: Our team answers your questions and assists throughout the process.
                </li>
                <li>
                  <b>$500 Towards the Flight Ticket</b>: For Platinum subscribers, we offer up to $500 for your flight to the U.S.
                </li>
                <li>
                  And more.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel6"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6-content"
              id="panel6-header"
            >
              <Typography fontWeight={600}>
                How does the "Yearly Resubmission" feature work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Suppose you did not win the  DV lottery this year. In that case, Immigrateable.com offers a "Yearly Resubmission" service, which will automatically resubmit your application each year. You must maintain an active subscription to our appropriate paid service to take advantage of this service. This ensures your application is updated if necessary and resubmitted during the official submission period, enhancing your chances of success over time without any extra effort.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel7"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7-content"
              id="panel7-header"
            >
              <Typography fontWeight={600}>
                How does Immigrateable.com ensure my DV lottery application is submitted correctly?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Immigrateable.com uses advanced technology and expert reviews to check your application for errors and completeness. Our system and team make sure your application meets all official requirements before submission.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel8"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8-content"
              id="panel8-header"
            >
              <Typography fontWeight={600}>
                Can I update my application information after submission?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Once your DV lottery application is submitted to the US government  program, it cannot be changed. However, you can update your application information in your Immigrateable.com account before the next submission period. Updates will be reflected in your next DV Program application.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel9"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9-content"
              id="panel9-header"
            >
              <Typography fontWeight={600}>
                What are the advantages of using Immigrateable.com for my Green Card lottery application?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Choosing Immigrateable.com for your DV lottery application offers numerous benefits:
              </Typography>
              <ul>
                <li>
                  <b>Year-Round Application</b>: You can fill out your application with us at any time of the year, and we ensure it is submitted to the DV lottery program on time. This means you don't have to keep track of the official start dates—we handle it.
                </li>
                <li>
                  <b>Automatic Yearly Resubmission</b>: If you are not selected this year, we automatically resubmit your application each year on your behalf. This service saves you the hassle of reapplying and keeps your chances alive without additional effort.
                </li>
                <li>
                  <b>Embassy Interview Guidance</b>: If you win, we will guide you through the interview process with the US embassy, preparing you to make the best impression.
                </li>
                <li>
                  <b>Green Card Application Review</b>: Winners will also benefit from our complimentary review of their Green Card application package, a service valued at $1000.
                </li>
                <li>
                  <b>$500 Towards the Flight Ticket</b>: For the Platinum subscribers, we offer up to $500 USD flight ticket reimbursement to fly to the USA to activate their Green Card.
                </li>
                <li>
                  <b>And More</b>: Our dedicated support and expert advice are available throughout your journey to secure a Green Card, enhancing your experience and improving your chances of success.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel10"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10-content"
              id="panel10-header"
            >
              <Typography fontWeight={600}>
                Does Immigrateable.com guarantee to win the DV lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Not Immigrateable.com nor anyone can guarantee a win because the DV Lottery is a random selection process. However, we ensure your application is correctly completed and submitted on time each year, increasing your chances.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel11"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11-content"
              id="panel11-header"
            >
              <Typography fontWeight={600}>
                Can I get my money back if I don't win the DV lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                No, refunds are not available if you don't win. Immigrateable.com provides services to review and submit your application accurately. Our fees cover these services and cannot be refunded based on the lottery outcome.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel12"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel12")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12-content"
              id="panel12-header"
            >
              <Typography fontWeight={600}>
                Can my spouse and I apply for the Green Card lottery separately?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Yes, spouses are allowed to submit separate entries for the Green Card lottery, and it is, in fact, one of the most effective strategies to increase your chances of winning. Each spouse can apply independently as long as both meet the eligibility requirements of the DV lottery. If either spouse is selected, the other can be included as a derivative spouse on the winning application, which allows both to apply for a Green Card. This method effectively doubles your family's chances of being selected in the lottery.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel13"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel13")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13-content"
              id="panel13-header"
            >
              <Typography fontWeight={600}>
                What are the advantages of using Immigrateable.com’s AI technology?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Immigrateable.com’s AI technology helps by:
              </Typography>
              <ul>
                <li>
                  <b>Error Reduction</b>: Checking your application for common errors.
                </li>
                <li>
                  <b>Optimized Submission Timing</b>: Submitting your application at the best time.
                </li>
                <li>
                  <b>Photo Validation</b>: Ensuring your photo meets DV Lottery requirements.
                </li>
                <li>
                  <b>Automated Resubmission</b>: Automatically preparing your application for the next year if you don't win.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel14"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel14")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel14-content"
              id="panel14-header"
            >
              <Typography fontWeight={600}>
                Will I receive an official DV lottery submission number?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Yes, after Immigrateable.com officially submits your application to the Green Card lottery, you will receive a DV lottery Entrant Confirmation Number.
              </Typography>
              <Typography>
                <p>
                  <strong>Please note:</strong> This confirmation number is provided ONLY after your application has been submitted to the official DV lottery program during the official submission period, which typically runs from October 4th to November 7th each year.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel15"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel15")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15-content"
              id="panel15-header"
            >
              <Typography fontWeight={600}>
                What are my chances of winning the Green Card Lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                To understand your chances of winning the Green Card Lottery, let’s look at some key statistics (for the year 2024) and calculations:
              </Typography>
              <ul>
                <li>
                  Total world population (2024 estimate): 8 billion.
                </li>
                <li>
                  Population of countries <u>not eligible</u> for DV lottery: 3.354 billion.
                </li>
              </ul>
              <p>
                By subtracting the population of ineligible countries from the total world population, we have:
              </p>
              <ul><li>Potentially eligible population: 8 billion - 3.354 billion = 4.646 billion</li></ul>
              <p>
                Next, we account for the percentage of the global population under 18 (not eligible to apply for the DV Lottery), estimated at approximately 26%. Subtracting this proportion from the eligible population gives:
              </p>
              <ul><li>Eligible adults: 4.646 billion × (1 - 0.26) = 3.438 billion</li></ul>
              <p>Therefore, approximately 3.438 billion people worldwide could have qualified to play the DV lottery in 2024.</p>
              <p>However, only 22.2 million people applied in the year 2024. This means only about 0.82% of people who qualified actually applied.</p>
              <p>
                <b>Your Winning Odds:</b> With such a low application rate relative to the number of eligible individuals, the odds of winning are approximately from 1 in 140 to 1 in 175. This shows that while the competition is significant, the likelihood of <b>winning can be favorable</b> compared to other lotteries. It’s beneficial to apply sooner as awareness and participation could increase over time, potentially making the lottery more competitive.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel16"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel16")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel16-content"
              id="panel16-header"
            >
              <Typography fontWeight={600}>
                How does Immigrateable.com increase the likelihood of winning the DV lottery?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Immigrateable.com boosts your chances of winning the DV lottery by automatically resubmitting your application each year. This method utilizes the cumulative odds of winning over time, which significantly improves with regular participation. Here’s a detailed breakdown:
              </Typography>
              <ul>
                <li>
                  <b>Annual Odds Calculation</b>: Annually, the U.S. government offers up to 55,000 diversity visas. For example, in DV-2024, about 22,185,619 people applied. The odds of winning in 2024 were:
                </li>
              </ul>
              <Box><Container>
                <Box
                  component="img"
                  src={AnnualOddsImg}
                  alt={`AnnualOdds = 22,185,619 / 55,000 ≈ 0.248%`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '30rem',
                  }}
                /></Container></Box>
              <p>
                <b>Cumulative Odds Over Multiple Years:</b> The chance of not winning in a given year is roughly 99.752%. This probability is compounded each year to compute the odds of not winning over multiple years. The odds of winning at least once over multiple years are calculated by subtracting the compounded odds of not winning from 100%
              </p>
              <ul>
                <li>Over 5 years: The odds of winning at least once increase to about <b>1.24%.</b></li>
                <li>Over 10 years: The odds improve to approximately <b>2.46%.</b></li>
                <li>Over 20 years: The odds of winning at least once rise to around <b>4.90%.</b></li>
              </ul>
              <p>
                Immigrateable.com uses this  strategy to ensure you consistently remain in the pool of applicants each year, maximizing your potential for success.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ borderRight: 0, borderLeft: 0 }}
            expanded={expanded === "panel17"}
            onChange={(_, isExpanded) => handleChange(isExpanded, "panel17")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel17-content"
              id="panel17-header"
            >
              <Typography fontWeight={600}>
                How does applying for the Green Card Lottery as a family increase your chances of winning?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Typography>
                Applying for the DV lottery as a family can significantly enhance your odds of winning by utilizing the multiple application opportunities allowed within the rules. Here's how this strategy works:
              </Typography>
              <p style={{margin: "20px 0"}}><b>Doubling Your Chances with Spousal Applications:</b></p>
              <ul>
                <li><b>Your Application</b>: You apply as the primary applicant, with your spouse listed as a dependent.</li>
                <li><b>Spouse's Application</b>: Your spouse applies as the primary applicant, with you listed as a dependent. This approach effectively doubles your chances, as each application is treated independently. If you or your spouse are selected, you can immigrate to the U.S.</li>
              </ul>
              <p style={{margin: "20px 0"}}> <b>Multiplying Chances with Children's Applications:</b> If your children are between the ages of 18 and 21, they can apply independently:</p>
              <ul>
                <li><b>Children's Applications</b>: Each child applies as the primary applicant, with both parents (and other eligible siblings) listed as dependents. This method multiplies your family's overall chances, as each eligible family member's independent application adds another opportunity to be selected.</li>
              </ul>
              <p style={{margin: "20px 0"}}>
                <b>Example Scenario</b>: Suppose this year you, your spouse, and two children are all eligible to apply:
              </p>
              <ul>
                <li><b>Your Application</b>: Includes you as the primary applicant, with your spouse and children as dependents.</li>
                <li><b>Spouse's Application</b>: Your spouse is the primary applicant, with you and the children as dependents.</li>
                <li><b>Child 1's Application</b>: Child 1 is the primary applicant, with both parents and Child 2 as dependents.</li>
                <li><b>Child 2's Application</b>: Child 2 is the primary applicant, with both parents and Child 1 as dependents.</li>
              </ul>
              <p style={{margin: "20px 0 0 0"}}>Thus, in one try you have 4 applications with your name on instead of just 1. </p>

            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </>
  );
}
