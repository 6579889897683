import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  TextField,
  CircularProgress,
  Typography,
  Grid,
  Box,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext";

const UpdatePasswordForm: React.FC = () => {
  const { logout } = useAuth();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | any>("");
  const [serverErrorMessage, setServerErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const { token } = useParams();

  useEffect(() => {
    logout();
  }, [logout]);

  const validatePasswords = (): boolean => {
    if (password === "" || confirmPassword === "") {
      setError(true);
      setErrorMessage("Passwords do not match or are empty");
      return false;
    }
    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage("Passwords do not match");
      return false;
    }
    setError(false);
    setErrorMessage("");
    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validatePasswords();
    const csrfToken = localStorage.getItem("csrfToken");
    if (isValid && token) {
      try {
        setLoading(true);
        setSuccess(false);
        setServerErrorMessage("");
        const response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_UPDATE_PASSWORD_URL}/${token}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token,
              "CSRF-Token": csrfToken || "",
            },
            credentials: "include",
            body: JSON.stringify({ password }),
          },
        );

        if (response.ok) {
          setSuccess(true);
          navigate("/account/login");
        } else {
          const errorMessage = await response.text();
          setError(true);
          setServerErrorMessage(
            "Failed to update password: Please reset your password again.",
          );
          console.error("Failed to update password:", errorMessage);
        }
      } catch (error) {
        setError(true);
        setServerErrorMessage(
          "Failed to update password: Please reset your password again.",
        );
        console.error("Failed to update password:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "password") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Box sx={{ maxWidth: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h6" component="h2" gutterBottom>
        Change Password
      </Typography>
      <form id="update-password-form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="New Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={handleChange}
              error={error}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              value={confirmPassword}
              onChange={handleChange}
              error={error}
              helperText={error ? errorMessage : ""}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {success && !loading && (
              <CheckCircleIcon style={{ color: "green" }} />
            )}
          </Grid>
          <Grid item xs={12}>
            {serverErrorMessage && (
              <Typography variant="body1" color="error">
                Failed to update password:{" "}
                <Link to="/account/reset-password" style={{ color: "blue" }}>
                  Please reset your password again.
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdatePasswordForm;
