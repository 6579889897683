import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext.tsx";
import useDarkMode from "../../useDarkMode.ts";
import Cookies from "js-cookie";

const LoginForm: React.FC = () => {
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [error, setError] = React.useState("");
  const [errorVerify, setErrorVerify] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [alertSuccess, setAlertSuccess] = React.useState(false);

  const isDarkMode = useDarkMode();

  // Function to extract UTM parameters and userId from the URL, then save them to cookies and local storage
  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};

    [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_id",
      "utm_term",
      "utm_content",
    ].forEach((key) => {
      const value = urlParams.get(key);
      if (value) {
        utmParams[key] = value;
        localStorage.setItem(key, value);
        Cookies.set(key, value, { expires: 30 }); // Set the cookie to expire in 30 days
      }
    });

    // Extract userId from the URL
    const userID = urlParams.get("userID");
    if (userID) {
      localStorage.setItem("userID", userID);
      Cookies.set("userID", userID, { expires: 30 }); // Set the cookie to expire in 30 days
    }

    return { ...utmParams, userID };
  };

  useEffect(() => {
    logout();
    getUTMParameters(); // Extract and store UTM parameters and userId when the component loads
  }, [logout]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setError("");
    setErrorVerify(false); // Reset the errorVerify state
  
    if (!email) {
      setEmailError("Please enter an email");
      return;
    }
    if (!email.includes("@")) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!password) {
      setPasswordError("Please enter a password");
      return;
    }
  
    try {
      await login(email, password, rememberMe);
      navigate("/applications");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
  
        // Example: Handling statusCode if available
        if ((error as any).statusCode === 409 || error.message.includes("verify your email")) {
          setErrorVerify(true); // Trigger the resend email button
        }
      } else {
        setError("An unexpected error occurred");
      }
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const csrfToken = localStorage.getItem("csrfToken");

  const handleResendVerificationEmail = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_RESEND_EMAIL_URL}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken || "",
          },
          body: JSON.stringify({ email }),
          credentials: "include",
        },
      );

      if (!response.ok) {
        throw new Error("Failed to send verification email");
      }

      setAlertSuccess(true);
      setErrorVerify(false)
    } catch (error) {
      console.error(error);
      setAlert(true);
    }
  };

  const handleClose = () => {
    setAlert(false);
    setAlertSuccess(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Log In to Your Account
        </Typography>
        <Box
          component="form"
          id="login-form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit}
        >
          <TextField
            error={!!emailError || !!error}
            helperText={emailError}
            margin="normal"
            autoFocus
            required
            fullWidth
            type="email"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            error={!!passwordError || !!error}
            helperText={passwordError || error}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* {error && (
              <Button
                onClick={handleResendVerificationEmail}
                color="primary"
                sx={{ textTransform: "none" }}
              >
                Send the Verification Email
              </Button>
            )} */}
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label="Remember me"
            />
            <Link
              to="/account/reset-password"
              style={{ textDecoration: "none", color: "blue" }}
            >
              Forgot Password?
            </Link>
          </Grid>
          {errorVerify ? (
          <Button
            onClick={handleResendVerificationEmail}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Send the Verification Email
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
        )}
          <Grid display="flex" alignItems="flex-start" flexDirection="column">
            <Typography variant="body1">
              New to Immigrateable?{" "}
              <Link
                to="/account/signup"
                style={{ textDecoration: "none", color: "blue" }}
              >
                Sign Up
              </Link>
            </Typography>
            
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={alert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
        Failed to send verification email.
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
        Verification email sent successfully.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;
