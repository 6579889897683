import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { PersonalInfoContext } from "./PersonalInfoContext";

export interface FamilyMemberInterface {
  id: string;
  firstname: string;
  lastname: string;
  middlename: string;
  dob: string;
  gender: string;
  city_of_birth: string;
  country_of_birth: string;
  photo: string;
}

export interface ChildrenInfoInterface {
  firstname: string;
  middlename: string;
  lastname: string;
  dob: string;
  photo: string;
  id?: number;
}

export interface FamilyDetailsInterface {
  have_spouse: boolean;
  spouse: FamilyMemberInterface;
  have_children: boolean;
  children: ChildrenInfoInterface[];
  id: string;
  userId: string | null;
}

interface FamilyDetailsContextType {
  familyDetails: FamilyDetailsInterface;
  setFamilyDetails: (
    name: keyof FamilyDetailsInterface,
    value: FamilyDetailsInterface[keyof FamilyDetailsInterface],
  ) => void;
  setChildrenInfo: (
    childId: number,
    name: keyof ChildrenInfoInterface,
    value: ChildrenInfoInterface[keyof ChildrenInfoInterface],
  ) => void;
  addChild: () => void;
  removeChild: (childId: number) => void;
  resetFamilyDetails: () => void;
  resetSpouseDetails: () => void;
  isLoading: boolean;
}

export const defaultFamilyDetails: FamilyDetailsInterface = {
  id: "",
  have_spouse: false,
  spouse: {
    firstname: "",
    lastname: "",
    middlename: "",
    dob: "",
    gender: "",
    city_of_birth: "",
    country_of_birth: "",
    photo: "",
    id: "",
  },
  have_children: false,
  children: [
    // {
    //   firstname: "",
    //   middlename: "",
    //   lastname: "",
    //   dob: "",
    //   photo: "",
    //   id: Date.now(),
    // },
  ],
  userId: null
};

export const FamilyDetailsContext = createContext<FamilyDetailsContextType>({
  familyDetails: defaultFamilyDetails,
  setFamilyDetails: () => {},
  setChildrenInfo: () => {},
  addChild: () => {},
  removeChild: () => {},
  isLoading: false,
  resetFamilyDetails: () => {},
  resetSpouseDetails: () => {},
});

export const FamilyDetailsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [familyDetails, setFamilyDetails] =
    useState<FamilyDetailsInterface>(defaultFamilyDetails);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const timeoutIdRef = useRef<number | null>(null);

  const { personalInfo } = useContext(PersonalInfoContext);
  const id = personalInfo.id;
  const userId = personalInfo.userId;

  useEffect(() => {
    const familyDetailsStore = localStorage.getItem("familyDetails");
    if (familyDetailsStore && familyDetailsStore !== "undefined") {
      setFamilyDetails(JSON.parse(familyDetailsStore));
    }
  }, []);

  useEffect(() => {
    // Check if there are children and update have_children accordingly
    const haveChildren = familyDetails.children.length > 0;
    if (familyDetails.have_children !== haveChildren) {
      setFamilyDetails((prevFamilyDetails) => ({
        ...prevFamilyDetails,
        have_children: haveChildren,
      }));
    }
  }, [familyDetails.children]);

  const saveFamilyDetails = useCallback(
    (newFamilyInfo: FamilyDetailsInterface) => {
      setIsLoading(true);
      const perid = localStorage.getItem("personalInfoId");
      const id = perid;
      const csrfToken = localStorage.getItem("csrfToken") || "";
      const endPoint = id
        ? `/${import.meta.env.VITE_APPLICATION_URL}/${id}`
        : `/${import.meta.env.VITE_APPLICATION_URL}`;

      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }

      const authToken = localStorage.getItem("authToken");
      const tempToken = localStorage.getItem("tempToken");

      timeoutIdRef.current = window.setTimeout(() => {
        const httpMethod = id ? "PATCH" : "PATCH";
        const userID = localStorage.getItem("userId");
        // console.log("diyorFAmily", authToken);
        fetch(`${import.meta.env.VITE_API_SERVER_URL}${endPoint}`, {
          method: httpMethod,
          credentials: "include",
          headers: {
            authorization: `Bearer ${authToken}`,
            verifyTempToken: `${tempToken}`,
            "Content-Type": "application/json",
            "Csrf-Token": csrfToken,
          },
          body: JSON.stringify({
            ...newFamilyInfo,
            id: newFamilyInfo.spouse.id,
            userId: userID,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setIsLoading(false);
            if (httpMethod === "PATCH") {
              setFamilyDetails((prevFamilyInfo) => {
                const newFamilyData = {
                  ...prevFamilyInfo,
                  id: data.id,
                };
                return newFamilyData;
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error, "error");
          });
      }, 1000);
    },
    [id, userId],
  );

  const updateFamilyDetails = useCallback(
    (
      name: keyof FamilyDetailsInterface,
      value: FamilyDetailsInterface[keyof FamilyDetailsInterface],
    ) => {
      setFamilyDetails((prevFamilyDetails) => {
        const updatedFamilyDetails = { ...prevFamilyDetails, [name]: value };
        localStorage.setItem(
          "familyDetails",
          JSON.stringify(updatedFamilyDetails),
        );
        saveFamilyDetails(updatedFamilyDetails);
        return updatedFamilyDetails;
      });
    },
    [saveFamilyDetails],
  );

  const resetFamilyDetails = () => {
    setFamilyDetails(defaultFamilyDetails);
    localStorage.removeItem("familyDetails");
    localStorage.removeItem("children");
  };

  const resetSpouseDetails = () => {
    setFamilyDetails((prevFamilyDetails) => {
      const updatedFamilyDetails = {
        ...prevFamilyDetails,
        spouse: {
          firstname: "",
          lastname: "",
          middlename: "",
          dob: "",
          gender: "",
          city_of_birth: "",
          country_of_birth: "",
          photo: "",
          id: "",
        },
        have_spouse: false,
      };
      localStorage.setItem(
        "familyDetails",
        JSON.stringify(updatedFamilyDetails),
      );
      return updatedFamilyDetails;
    });
  };

  const updateChildrenInfo = useCallback(
    (
      childId: number,
      name: keyof ChildrenInfoInterface,
      value: ChildrenInfoInterface[keyof ChildrenInfoInterface],
    ) => {
      setFamilyDetails((prevFamilyDetails) => {
        const updatedChildren = prevFamilyDetails.children.map((child) =>
          child.id === childId ? { ...child, [name]: value } : child,
        );
        const updatedFamilyDetails = {
          ...prevFamilyDetails,
          children: updatedChildren,
        };
        localStorage.setItem(
          "familyDetails",
          JSON.stringify(updatedFamilyDetails),
        );
        saveFamilyDetails(updatedFamilyDetails);
        // console.log("Updated children info", updatedFamilyDetails);
        return updatedFamilyDetails;
      });
    },
    [saveFamilyDetails],
  );

  const addChild = useCallback(() => {
    setFamilyDetails((prevFamilyDetails) => {
      const newChild: ChildrenInfoInterface = {
        firstname: "",
        middlename: "",
        lastname: "",
        dob: "",
        photo: "",
        id: Date.now(),
      };
      const updatedFamilyDetails = {
        ...prevFamilyDetails,
        children: [...prevFamilyDetails.children, newChild],
        have_children: true,
      };
      localStorage.setItem(
        "familyDetails",
        JSON.stringify(updatedFamilyDetails),
      );
      saveFamilyDetails(updatedFamilyDetails);
      console.log("Added new child", updatedFamilyDetails);
      return updatedFamilyDetails;
    });
  }, [saveFamilyDetails]);

  const removeChild = useCallback(
    (childId: number) => {
      setFamilyDetails((prevFamilyDetails) => {
        const updatedChildren = prevFamilyDetails.children.filter(
          (child) => child.id !== childId,
        );
        const updatedFamilyDetails = {
          ...prevFamilyDetails,
          children: updatedChildren,
          have_children: prevFamilyDetails.children.length > 1,
        };
        localStorage.setItem(
          "familyDetails",
          JSON.stringify(updatedFamilyDetails),
        );
        saveFamilyDetails(updatedFamilyDetails);
        console.log("Removed child", updatedFamilyDetails);
        return updatedFamilyDetails;
      });
    },
    [saveFamilyDetails],
  );

  return (
    <FamilyDetailsContext.Provider
      value={{
        familyDetails,
        setFamilyDetails: updateFamilyDetails,
        setChildrenInfo: updateChildrenInfo,
        addChild,
        removeChild,
        isLoading,
        resetFamilyDetails,
        resetSpouseDetails,
      }}
    >
      {children}
    </FamilyDetailsContext.Provider>
  );
};
