import { Box, Button, Container, Grid, Typography } from "@mui/material";
import "./_BeginJourney.scss";
import { Link as RouterLink } from "react-router-dom";

import BeginJourneyImage from "../../assets/images/begin-journey.png";

export default function BeginJourney() {
  return (
    <Container className="begin-journey" maxWidth="xl" component="section">
      <Grid
        container
        spacing={6}
        display="flex"
        flexDirection="row"
        p={4}
        alignItems="center"
      >
        <Grid
          item
          sm={6}
          xs={12}
          display="flex"
          flexDirection="column"
          justifyContent="stretch"
          textAlign="left"
          alignItems="center"
        >
          <Typography
            variant="h4"
            className="begin-journey__title"
            mb={4}
            fontWeight="bold"
          >
            Begin Your Journey to the American Dream with <span>EasyDV</span>
          </Typography>
          <Typography variant="body1" mb={4}>
            Sign up with EasyDV now and let our AI-driven service take the
            stress out of your DV Lottery applications. Remember, it's more than
            just an application; it's a step towards your future.
          </Typography>
          <Box className="begin-journey__cta">
            <Button
              id="start-for-free-button"
              className="dark-blue-text"
              component={RouterLink}
              to="/application/personal-details"
              sx={{
                borderRadius: 5,
                backgroundColor: "#C54B3A",
                px: 4,
                color: "white",
                "&:hover": {
                  backgroundColor: "#083657",
                },
              }}
            >
              Start for free
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          display="small"
          flexDirection="column"
          justifyContent="center"
        >
          <img
            width="100%"
            src={BeginJourneyImage}
            alt="Begin Your Journey to the American Dream with EasyDV"
            className="begin-journey__image"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
