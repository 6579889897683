import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./UserDetailsForm.scss";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import PersonalDetails from "./PersonalDetails/PersonalDetails.tsx";
import FamilyDetails from "./FamilyDetails/FamilyDetails.tsx";
import Address from "./Address/Address.tsx";
import Review from "./Review/Review.tsx";
import { Fragment, useContext, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  PersonalInfo,
  PersonalInfoContext,
} from "../../context/PersonalInfoContext.tsx";
import Modal from "../../components/Modal/Modal";
import {
  ContactInfo,
  ContactInfoContext,
} from "../../context/ContactInfoContext.tsx";
import {
  FamilyDetailsContext,
  FamilyDetailsInterface,
  defaultFamilyDetails,
} from "../../context/FamilyDetailsContext.tsx";
import refreshAccessToken from "../../utils/fetchWithToken.ts";
import Payment from "./Payment/Payment.tsx";

export default function Userdetailsform() {
  const { personalInfo, setPersonalInfo, resetPersonalInfo } =
    useContext(PersonalInfoContext);
  const { contactInfo, setContactInfo, resetContactInfo } =
    useContext(ContactInfoContext);
  const { familyDetails, setFamilyDetails, resetFamilyDetails } =
    useContext(FamilyDetailsContext);
  const { step } = useParams() || "personal-details";
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { isAuthenticated } = useAuth();
  const [showEdit, setShowEdit] = useState(false);
  const [promptInfo, setpromptInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [stepCLicksAllowed, setStepCLicksAllowed] = useState(false);
  const [modal, setModal] = useState(false);
  const [spouseMissingInfo, setspouseMissingInfo] = useState(false);
  const [childrenMissingInfo, setchildrenMissingInfo] = useState(false);
  const location = useLocation();
  const backgroundColor = stepCLicksAllowed ? "#C54B3A" : "#C0C0C0";
  const hoverColor = stepCLicksAllowed ? "#083657" : "#C0C0C0";
  const [prompt, setPrompt] = useState(false);

  useEffect(() => {
    if(isAuthenticated){
      fetchPersonalInfo();
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("personalInfo")) {
      resetPersonalInfo();
      resetContactInfo();
      resetFamilyDetails();
      localStorage.removeItem("personalInfoId");
    }
  }, []);

  interface Child {
    firstname?: string;
    middlename?: string;
    lastname?: string;
    dob?: string;
    photo?: string;
  }

  interface Spouse {
    firstname?: string;
    middlename?: string;
    lastname?: string;
    dob?: string;
    photo?: string;
  }

  const removeAppIdFromUrl = () => {
    const currentPath = location.pathname;
    const newPath = "/application/personal-details";

    if (currentPath !== newPath) {
      navigate(newPath, { replace: true }); // Replace current URL without pushing to history stack
    } else {
      navigate(newPath); // Push the same path to force navigation
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("personalInfo")) {
      removeAppIdFromUrl();
    }
  }, [localStorage]);

  const formatDateForInput = (dateString: string): string => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchPersonalInfo = async () => {
    const csrfToken = localStorage.getItem("csrfToken") || "";
    const pathParts = window.location.pathname.split("/application/");
    if (pathParts.length < 2) {
      console.error("Invalid path, application ID not found");
      return;
    }
    const applicationId = pathParts[1].split("/")[0];
    console.log("applica ID", applicationId);
    if (applicationId !== "personal-details" && applicationId !== "payment") {
      try {
        let authToken = localStorage.getItem("authToken");

        let response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${applicationId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "CSRF-Token": csrfToken,
            },
          },
        );

        if (response.status === 403) {
          console.log("Received 403 status, refreshing token...");
          await refreshAccessToken();
          authToken = localStorage.getItem("authToken");

          response = await fetch(
            `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_APPLICATION_URL}/${applicationId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
                "CSRF-Token": csrfToken,
              },
            },
          );
        }

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const personalinfo = {
          id: data.id,
          applicationId: data.applicationId,
          userId: data.userId,
          firstname: data.firstname,
          middlename: data.middlename,
          lastname: data.lastname,
          dob: data.dob ? formatDateForInput(data.dob) : null,
          gender: data.gender,
          countryOfBirth: data.countryOfBirth,
          cityOfBirth: data.cityOfBirth,
          countryOfEligibility: data.countryOfEligibility,
          email: data.email,
          photo: data.photo,
          payment_status: data.payment_status
        };
        localStorage.setItem("personalInfo", JSON.stringify(personalinfo));
        localStorage.setItem("personalInfoId", data.id);
        (
          Object.entries(personalinfo) as [
            keyof PersonalInfo,
            string | File | null | undefined,
          ][]
        ).forEach(([key, value]) => {
          setPersonalInfo(key, value);
        });
        const contactinfo = {
          id: data.id,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          city: data.city,
          postalCode: data.postalCode,
          country: data.country,
          currentCountry: data.currentCountry,
          phone: data.phone,
        };
        localStorage.setItem("contactInfo", JSON.stringify(contactinfo));
        (
          Object.entries(contactinfo) as [
            keyof ContactInfo,
            string | undefined,
          ][]
        ).forEach(([key, value]) => {
          if (value !== undefined) {
            setContactInfo(key, value);
          }
        });
        const familyDetailsData: FamilyDetailsInterface = {
          have_spouse: !!data.spouse,
          spouse: data.spouse
            ? {
                ...data.spouse, // Spread all other properties
                dob: data.spouse.dob ? formatDateForInput(data.spouse.dob) : "" // Format the spouse's date of birth if it exists
              }
            : defaultFamilyDetails.spouse,
          have_children: Array.isArray(data.children) && data.children.length > 0,
          children: (data.children || defaultFamilyDetails.children).map((child: Child) => ({
            ...child, // Spread all other properties
            dob: child.dob ? formatDateForInput(child.dob) : ""
          })),
          id: data.id,
          userId: data.userId,
        };
        setFamilyDetails("have_spouse", familyDetailsData.have_spouse);
        setFamilyDetails("spouse", familyDetailsData.spouse);
        setFamilyDetails("have_children", familyDetailsData.have_children);
        setFamilyDetails("children", familyDetailsData.children);
        localStorage.setItem(
          "familyDetails",
          JSON.stringify(familyDetailsData),
        );
      } catch (error) {
        console.error("Failed to fetch personal info:", error);
      }
    }
  };

  useEffect(() => {
    switch (step) {
      case "personal-details":
        setActiveStep(0);
        break;
      case "address":
        setActiveStep(1);
        break;
      case "family-details":
        setActiveStep(2);
        break;
      case "payment":
        setActiveStep(3);
        break;
      case "review":
        setActiveStep(4);
        setPrompt(true);
        break;
    }
  }, [step]);

  useEffect(() => {
    if (validateEmail(personalInfo.email)) {
      setMessage("");
    }
  }, [personalInfo.email]);

  /*  const saveData = (data: any) => {
      console.log(data, "data");
      localStorage.setItem("userDetails", JSON.stringify(data));
    };*/

  const setAddress = (currentStep: number) => {
    console.log(currentStep, "currentStep");
    switch (currentStep) {
      case 0:
        navigate("/application/personal-details");
        break;
      case 1:
        navigate("/application/address");
        break;
      case 2:
        navigate("/application/family-details");
        break;
      case 3:
        navigate("/application/payment");
        break;
      case 4:
        navigate("/application/review");
        break;
    }
  };

  const validateEmail = (email: string): boolean => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const steps = ["Personal details", "Address", "Family details", "Payment", "Review"];

  const handleNext = () => {
    if (personalInfo.email !== "" && validateEmail(personalInfo.email)) {
      if (activeStep === steps.length - 1) {
        handlePopupOpen();
      } else {
        setActiveStep((prevActiveStep) => {
          setAddress(prevActiveStep + 1);
          return prevActiveStep + 1;
        });
      }
      setMessage("");
    } else if (personalInfo.email == "") {
      setMessage("Please enter a valid email address");
      console.log(message);
    } else {
      setMessage("Invalid email address");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setAddress(prevActiveStep - 1);
      return prevActiveStep - 1;
    });
  };

  const personalInfoFields = [
    personalInfo.firstname,
    personalInfo.lastname,
    personalInfo.gender,
    personalInfo.dob,
    personalInfo.countryOfBirth,
    personalInfo.cityOfBirth,
    personalInfo.countryOfEligibility,
    personalInfo.photo,
  ];
  const contactInfoFields = [
    contactInfo.country,
    contactInfo.addressLine1,
    contactInfo.city,
    contactInfo.phone,
    contactInfo.postalCode,
  ];

  const familyInfoFields = [
    ...(familyDetails.have_spouse
      ? [
          familyDetails.spouse.firstname,
          familyDetails.spouse.lastname,
          familyDetails.spouse.dob,
          familyDetails.spouse.photo,
        ]
      : []),
  ];
  const childrenInfoFields = [
    ...(familyDetails.have_children
      ? familyDetails.children.flatMap((child) => [
          child.firstname,
          child.lastname,
          child.dob,
          child.photo,
        ])
      : []),
  ];

  function checkMissingInfo(fields: any): boolean {
    if (!Array.isArray(fields)) {
      return true; // or handle the case where fields is not an array
    }
    return fields.every((field) => !!field); // Perform your logic here
  }

  const handlePopupOpen = () => {
    const personalMissingInfo = checkMissingInfo(personalInfoFields);
    const contactMissingInfo = checkMissingInfo(contactInfoFields);
    const spouseMissingInfo = checkMissingInfo(familyInfoFields);
    const childrenMissingInfo = checkMissingInfo(childrenInfoFields);

    if (familyDetails.have_spouse && familyDetails.have_children) {
      if (
        personalMissingInfo &&
        contactMissingInfo &&
        childrenMissingInfo &&
        spouseMissingInfo
      ) {
        if (isAuthenticated) {
          navigate("/applications");
          setAlertSuccess(true);
        } else {
          navigate("/account/login");
          setAlertSuccess(true);
        }
      } else {
        setAlert(true);
      }
    } else if (familyDetails.have_spouse && !familyDetails.have_children) {
      if (personalMissingInfo && contactMissingInfo && spouseMissingInfo) {
        if (isAuthenticated) {
          navigate("/applications");
          setAlertSuccess(true);
        } else {
          navigate("/login");
          setAlertSuccess(true);
        }
      } else {
        setAlert(true);
      }
    } else if (!familyDetails.have_spouse && familyDetails.have_children) {
      if (personalMissingInfo && contactMissingInfo && childrenMissingInfo) {
        if (isAuthenticated) {
          navigate("/applications");
          setAlertSuccess(true);
        } else {
          navigate("/login");
          setAlertSuccess(true);
        }
      } else {
        setAlert(true);
      }
    } else if (!familyDetails.have_spouse && !familyDetails.have_children) {
      if (personalMissingInfo && contactMissingInfo) {
        if (isAuthenticated) {
          navigate("/applications");
          setAlertSuccess(true);
        } else {
          navigate("/login");
          setAlertSuccess(true);
        }
      } else {
        setAlert(true);
      }
    }
  };

  const handleClose = () => {
    setAlert(false);
    setAlertSuccess(false);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    const allowedPaths = [
      "personal-details",
      "address",
      "family-details",
      "payment",
      "review",
    ];
  
    const storedPersonalInfo = localStorage.getItem("personalInfo");
    const personalInfo = storedPersonalInfo
      ? JSON.parse(storedPersonalInfo)
      : null;
  
    if (!personalInfo) {
      return;
    }
  
    const url = new URL(window.location.href);
    const applicationId = personalInfo.id;
    const currentPath = url.pathname;
    const pathSegments = currentPath.split("/").filter((segment) => segment);
  
    // Extract and preserve query parameters
    const queryParams = new URLSearchParams(url.search);
  
    const applicationsIndex = pathSegments.indexOf("application");
  
    if (applicationsIndex !== -1 && applicationId) {
      // Ensure the applicationId is right after "application"
      if (pathSegments[applicationsIndex + 1] !== applicationId) {
        pathSegments.splice(applicationsIndex + 1, 0, applicationId);
      }
  
      // Retain only allowed paths after the applicationId
      const newSegments = pathSegments.slice(0, applicationsIndex + 2);
      const remainingSegments = pathSegments.slice(applicationsIndex + 2);
      for (const segment of remainingSegments) {
        if (allowedPaths.includes(segment)) {
          newSegments.push(segment);
        } else {
          break;
        }
      }
  
      const newPath = "/" + newSegments.join("/");
      
      // Build new URL with or without query parameters
      const newUrl = `${url.origin}${newPath}${queryParams.toString() ? '?' + queryParams.toString() : ''}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [personalInfo?.id]);
  

  useEffect(() => {
    if (
      personalInfo.id &&
      validateEmail(personalInfo.email) &&
      personalInfo.email
    ) {
      setStepCLicksAllowed(true);
    } else {
      setStepCLicksAllowed(false);
    }
  }, [personalInfo.id, personalInfo.email]);

  const componentList = [
    <PersonalDetails
      showEdit={showEdit}
      promptInfo={prompt}
      message={message}
    />,
    <Address showEdit={showEdit} promptInfo={prompt} />,
    <FamilyDetails showEdit={showEdit} promptInfo={prompt} />,
    <Payment/>,
    <Review setAddress={setAddress} />,
  ];

  return (
    <>
      <Box component="section" className="user-details-form">
        <Box sx={{ width: "100%", mt: 6 }}>
          <Container maxWidth="md">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const stepPath = label.replace(/\s+/g, "-").toLowerCase();
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    disabled={
                      location.pathname.includes(stepPath) || !stepCLicksAllowed
                    }
                  >
                    <StepButton
                      color="inherit"
                      onClick={() => {
                        setAddress(index);
                        setActiveStep(index);
                      }}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </Container>
          <Container sx={{ mt: 8 }}>
            {!isAuthenticated && activeStep == 4 ? (
              <Grid container>
                {/* <Grid item xs={12}>
                <Typography variant="h6">
                  You need to be logged in to continue
                </Typography>
              </Grid>
              <AuthForm /> */}
                <Review setAddress={setAddress} />
              </Grid>
            ) : (
              componentList[activeStep]
            )}
          </Container>
          {activeStep === steps.length ? (
            <Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Fragment>
          ) : (
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: 6,
                }}
              >
                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={4}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  display="flex"
                  mb={4}
                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    alignContent="flex-end"
                  >
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button
                      className="action-button-red"
                      // disabled={!stepCLicksAllowed}
                      onClick={handleNext}
                      size="medium"
                      variant="contained"
                      sx={{
                        borderRadius: 5,
                        backgroundColor: { backgroundColor },
                        px: 4,
                        color: "white",
                        "&:hover": {
                          backgroundColor: { hoverColor },
                        },
                        mr: 2,
                      }}
                    >
                      {activeStep >= steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          )}
        </Box>
      </Box>
      <Snackbar
        open={alert}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          Please, fill in the required fields
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          Your application submitted succesfully!
        </Alert>
      </Snackbar>
      {/* {modal && (
        <Modal close={setModal}>
          <Box className={"box"}>
            <Container>
              <Typography
                style={{ textAlign: "left" }}
                variant="h4"
                fontWeight="bold"
                sx={{ marginBottom: 2 }}
              >
                Please make sure:
              </Typography>

              <Box sx={{ marginBottom: 2 }} style={{ textAlign: "left" }}>
                <Typography variant="h6" fontWeight="bold">
                  1. All information is accurate and complete.
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  2. You agree to our terms and conditions.
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                  3. All necessary documents are attached.
                </Typography>
              </Box>

              <Box style={{ textAlign: "left" }} sx={{ marginBottom: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginBottom: 2 }}
                >
                  Note: Once we submit your application to DV Lottery, you
                  cannot make changes
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  Do you want to submit your DV lottery application?
                </Typography>
              </Box>

              <Box display="flex" gap="10px" style={{ textAlign: "left" }}>
                <Button variant="contained">Submit</Button>
                <Button variant="outlined" onClick={() => setModal(false)}>
                  Cancel
                </Button>
              </Box>
            </Container>
          </Box>
        </Modal>
      )} */}
    </>
  );
}
