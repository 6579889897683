import { Grid, Typography, useMediaQuery } from "@mui/material";
import theme from "../../theme";

type Props = {
  image: string;
  title: string;
  description: TrustedHTML;
  locationAttr: "left" | "right";
};

export function ImageRows({ image, title, description, locationAttr }: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12} alignItems="flex-start" mb={3}>
      <Grid
        container
        spacing={6}
        display="flex"
        flexDirection={
          isMobile ? "row" : locationAttr === "right" ? "row-reverse" : "row"
        }
        justifyContent="center"
        textAlign="left"
      >
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ paddingBottom: "10px", marginBottom: "10px" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="center"
        >
          <img src={image} alt={title} width="100%" style={{borderRadius: "10px"}}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
