// src/theme/overrides.ts
import { Components } from "@mui/material/styles";

const overrides: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
        // other styles
      },
    },
  },
  // ... other component overrides
};

export default overrides;
