import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const AutoLogInForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string>("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      setLoading(false);
      return;
    }

    try {
      const csrfToken = localStorage.getItem("csrfToken");
      const res = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_AUTO_LOGIN_URL}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "CSRF-Token": csrfToken || "",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        },
      );

      const data = await res.json();
      setResponse(data.message);
    } catch (error) {
      console.error("Error submitting email:", error);
      setResponse("Error submitting email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 4,
        p: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Submit Email
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (emailRegex.test(e.target.value)) {
              setEmailError("");
            } else {
              setEmailError("Invalid email address");
            }
          }}
          error={!!emailError}
          helperText={emailError}
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !!emailError}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </form>
      {response && (
        <Typography sx={{ mt: 2, color: "green" }}>{response}</Typography>
      )}
    </Box>
  );
};

export default AutoLogInForm;
