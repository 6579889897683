import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { decodeToken } from "../utils/token.ts";
import { PersonalInfoContext } from "./PersonalInfoContext.tsx";
import { ContactInfoContext } from "./ContactInfoContext.tsx";
import { FamilyDetailsContext } from "./FamilyDetailsContext.tsx";

interface AuthContextType {
  userDetails: any;
  isAuthenticated: boolean;
  userId: string | null; // Add userId
  login: (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => Promise<void>;
  signup: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
  ) => Promise<void>;
  confirm: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { personalInfo, setPersonalInfo, resetPersonalInfo } =
    useContext(PersonalInfoContext);
  const { contactInfo, setContactInfo, resetContactInfo } =
    useContext(ContactInfoContext);
  const { familyDetails, setFamilyDetails, resetFamilyDetails } =
    useContext(FamilyDetailsContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem("authToken");
    return !!token;
  });
  const [userDetails, setUserDetails] = useState<any>(() => {
    const user = localStorage.getItem("token-decoded");
    return user ? JSON.parse(user) : {};
  });
  const [userId, setUserId] = useState<string | null>(() => {
    return localStorage.getItem("userId");
  });
  const csrfToken = localStorage.getItem("csrfToken");

  const login = useCallback(
    async (
      email: string,
      password: string,
      rememberMe: boolean,
    ): Promise<void> => {
      const csrfToken = localStorage.getItem("csrfToken");
      const headers = {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken || "",
      } as HeadersInit;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_LOGIN_URL}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ email, password }),
            credentials: "include",
          },
        );
        const res = await response.json();
        localStorage.removeItem("tempToken");
        if (!response.ok) {
          console.log(res);
          throw new Error(` ${res.message}`);
        }

        const { accessToken, refreshToken, _id } = res;
        const userDetailsResponse = decodeToken(accessToken);
        const loginTimestamp = new Date().getTime();

        localStorage.setItem("authToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem(
          "token-decoded",
          JSON.stringify(userDetailsResponse) || "",
        );
        localStorage.setItem("userId", _id);
        localStorage.setItem("loginTimestamp", loginTimestamp.toString());

        if (rememberMe) {
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("rememberMe");
        }

        setUserDetails(userDetailsResponse || {});
        setUserId(userId);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Login error:", error);
        throw error;
      }
    },
    [],
  );

  const logout = useCallback((): void => {
    if(localStorage.getItem('authToken')){localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token-decoded");
    localStorage.removeItem("userId");
    localStorage.removeItem("loginTimestamp");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("personalInfo");
    localStorage.removeItem("personalInfoId");
    localStorage.removeItem("contactInfo");
    localStorage.removeItem("personalInfoId");
    localStorage.removeItem("familyDetails");
    localStorage.removeItem("lastExternalReferrerTime");
    resetPersonalInfo();
    resetContactInfo();
    resetFamilyDetails();
    setIsAuthenticated(false);
    setUserId(null);}
  }, []);

  const checkAutoLogout = useCallback((): void => {
    const loginTimestamp = localStorage.getItem("loginTimestamp");
    if (loginTimestamp) {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - parseInt(loginTimestamp, 10);
      const twentyFourHours = 24 * 60 * 60 * 1000;

      if (timeElapsed > twentyFourHours) {
        logout();
      }
    }
  }, [logout]);

  useEffect(() => {
    checkAutoLogout();
    const interval = setInterval(checkAutoLogout, 60 * 1000); // Check every minute
    return () => clearInterval(interval);
  }, [checkAutoLogout]);

  const signup = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
    ): Promise<void> => {
      const headers = {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken || "",
      } as HeadersInit;

      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_SIGNUP_URL}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ firstName, lastName, email, password }),
            credentials: "include",
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.log(errorData.message);
          throw new Error(errorData.message || "Signup failed");
        }

        const { token } = await response.json();
        localStorage.setItem("authToken", token);
      } catch (error) {
        console.error("Signup error:", error);
        if (
          error ==
          `SyntaxError: Unexpected token '<', "<!DOCTYPE "... is not valid JSON`
        ) {
          window.location.reload();
        }
        throw error;
      }
    },
    [csrfToken],
  );

  const confirm = useCallback(
    async (email: string, password: string): Promise<void> => {
      const headers = {
        "Content-Type": "application/json",
        "CSRF-Token": csrfToken || "",
      } as HeadersInit;

      try {
        const response = await fetch(
          `https://api.immigrateable.com/auth/signup`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ email, password }),
            credentials: "include",
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Confirm failed");
        }

        const { token } = await response.json();
        localStorage.setItem("authToken", token);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Signup error:", error);
        throw error;
      }
    },
    [csrfToken],
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userId,
        login,
        logout,
        signup,
        userDetails,
        confirm,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthContext };
