import { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./_Address.scss";
import axios from "axios";

import {
  alpha,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  styled,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faMap } from "@fortawesome/free-solid-svg-icons";
import { Item } from "../../../components/ItemComponent/Item.tsx";
import {
  ContactInfoContext,
  ContactInfo,
  defaultContactInfo,
} from "../../../context/ContactInfoContext";

// import { Link } from "react-router-dom";
import { PersonalInfoContext } from "../../../context/PersonalInfoContext.tsx";

const CustomInputStyle = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderRadius: 10,
    borderColor: error
      ? theme.palette.error.main
      : theme.palette.mode === "light"
        ? "#E0E3E7"
        : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(
        error
          ? "rgba(255, 0, 0, 0.25) 0 0 0 0.2rem"
          : theme.palette.primary.main,
        0.25,
      )} 0 0 0 0.2rem`,
    },
  },
}));

type AddressProps = {
  showEdit: boolean;
  promptInfo: boolean;
};

export default function Address({ showEdit, promptInfo }: AddressProps) {
  const { contactInfo, setContactInfo, isLoading } =
    useContext(ContactInfoContext);
  const { personalInfo, setPersonalInfo } = useContext(PersonalInfoContext);
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [isLoadingAddress1, setIsLoadingAddress1] = useState(false);
  const [isLoadingAddress2, setIsLoadingAddress2] = useState(false);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [isLoadingDistrict, setIsLoadingDistrict] = useState(false);
  const [isLoadingZip, setIsLoadingZip] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [validAddressPhoneNumber, setValidAddressPhoneNumber] =
    useState<boolean>(true);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.down("md"));
  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const [allCities, setAllCities] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [filteredCountries1, setFilteredCountries1] = useState<string[]>([]);

  const [selectedCountry1, setSelectedCountry1] = useState<string | null>();

  const [selectedCity, setSelectedCity] = useState<string | null>();

  const [countryInput1, setCountryInput1] = useState<string>();

  const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);

  useEffect(() => {
    const fetchCountries = async () => {
      const storedCountries = localStorage.getItem("countries");
      const storedCities = localStorage.getItem("allCities");
      if (storedCountries && storedCities) {
        setCountries(JSON.parse(storedCountries));
        setAllCities(JSON.parse(storedCities));
      } else {
        setLoadingCountries(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries",
          );
          const data = await response.json();
          const fetchedCountries = data.data.map(
            (country: any) => country.country,
          );
          const fetchedCities = data.data.flatMap(
            (country: any) => country.cities,
          );
          setCountries(fetchedCountries);
          setAllCities(fetchedCities);
          localStorage.setItem("countries", JSON.stringify(fetchedCountries));
          localStorage.setItem("allCities", JSON.stringify(fetchedCities));
        } catch (error) {
          console.error("Error fetching countries:", error);
        } finally {
          setLoadingCountries(false);
        }
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchCities = async (country: string) => {
      const storedCities = localStorage.getItem(`cities_${country}`);
      if (storedCities) {
        setCities(JSON.parse(storedCities));
      } else {
        setLoadingCities(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ country }),
            },
          );
          const data = await response.json();
          const fetchedCities = data.data;
          setCities(fetchedCities);
          localStorage.setItem(
            `cities_${country}`,
            JSON.stringify(fetchedCities),
          );
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setLoadingCities(false);
        }
      }
    };

    const storedCountry = contactInfo.country;
    if (storedCountry) {
      setSelectedCountry1(storedCountry);
      fetchCities(storedCountry);
    } else if (selectedCountry1) {
      fetchCities(selectedCountry1);
    } else {
      setCities(allCities);
    }
  }, [selectedCountry1, allCities]);

  useEffect(() => {
    if (countryInput1) {
      const filtered = countries.filter((country) =>
        country.toLowerCase().startsWith(countryInput1.toLowerCase()),
      );
      setFilteredCountries1(filtered);
    } else {
      setFilteredCountries1([]);
    }
  }, [countryInput1, countries]);

  useEffect(() => {
    const storedCity = contactInfo.city;
    if (storedCity) {
      setSelectedCity(storedCity);
    }
  }, []);

  const handleCountrySelect1 = (event: any, newValue: string | null) => {
    setSelectedCountry1(newValue);
    setSelectedCity(null);
    setContactInfo("city", "");
    localStorage.setItem("country", newValue || "");
    if (newValue) {
      const fetchCities = async () => {
        setLoadingCities(true);
        try {
          const response = await fetch(
            "https://countriesnow.space/api/v0.1/countries/cities",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ country: newValue }),
            },
          );
          const data = await response.json();
          const fetchedCities = data.data;
          setCities(fetchedCities);
          localStorage.setItem(
            `cities_${newValue}`,
            JSON.stringify(fetchedCities),
          );
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setLoadingCities(false);
        }
      };
      fetchCities();
    }
  };

  const handleCitySelect = (event: any, newValue: string | null) => {
    setSelectedCity(newValue);
    localStorage.setItem("city", newValue || "");
  };

  const handleCountryInput1Change = (event: any, newInputValue: string) => {
    setCountryInput1(newInputValue);
    if (!newInputValue) {
      setSelectedCountry1("");
      setSelectedCity(null);
      setContactInfo("country", "");
      setContactInfo("city", "");
      localStorage.removeItem("country");
      localStorage.removeItem("city");
    }
  };

  const handleCityInputChange = (event: any, newInputValue: string) => {
    setSelectedCity(newInputValue);
    if (!newInputValue) {
      setContactInfo("city", "");
      localStorage.removeItem("city");
    }
  };

  const filterOptions = (
    options: string[],
    { inputValue }: { inputValue: string },
  ) => {
    return options
      .filter((option) =>
        option.toLowerCase().startsWith(inputValue.toLowerCase()),
      )
      .slice(0, 10);
  };

  useEffect(() => {
    if (selectedCountry1) {
      setContactInfo("country", selectedCountry1);
      localStorage.removeItem("country");
      // localStorage.removeItem("country");
    }
  }, [selectedCountry1, setContactInfo]);

  useEffect(() => {
    if (selectedCity) {
      setContactInfo("city", selectedCity);
      localStorage.removeItem("city");
      // localStorage.removeItem("city");
    }
  }, [selectedCity, setContactInfo]);

  useEffect(() => {
    const allowedPaths = ["welcome", "address", "family-details", "review"];
    const url = new URL(window.location.href);
    const applicationId = personalInfo.id;
    const currentPath = url.pathname;
    const pathSegments = currentPath.split("/").filter((segment) => segment);

    const applicationsIndex = pathSegments.indexOf("application");

    if (applicationsIndex !== -1 && applicationId) {
      // Ensure the applicationId is right after "application"
      if (pathSegments[applicationsIndex + 1] !== applicationId) {
        pathSegments.splice(applicationsIndex + 1, 0, applicationId);
      }

      // Retain only allowed paths after the applicationId
      const newSegments = pathSegments.slice(0, applicationsIndex + 2);
      const remainingSegments = pathSegments.slice(applicationsIndex + 2);
      for (const segment of remainingSegments) {
        if (allowedPaths.includes(segment)) {
          newSegments.push(segment);
        } else {
          break;
        }
      }

      const newPath = "/" + newSegments.join("/");
      const newUrl = `${url.origin}${newPath}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [personalInfo.id]);

  const validAddressPhone = (phone: string): boolean => {
    const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    return regex.test(phone);
  };

  const loadingStates = {
    country: {
      country: true,
      addressLine1: false,
      addressLine2: false,
      city: false,
      currentCountry: false,
      phone: false,
      postalCode: false,
    },
    addressLine2: {
      country: false,
      addressLine1: false,
      addressLine2: true,
      city: false,
      currentCountry: false,
      phone: false,
      postalCode: false,
    },
    addressLine1: {
      country: false,
      addressLine1: true,
      addressLine2: false,
      city: false,
      currentCountry: false,
      phone: false,
      postalCode: false,
    },
    city: {
      country: false,
      addressLine1: false,
      addressLine2: false,
      city: true,
      currentCountry: false,
      phone: false,
      postalCode: false,
    },
    currentCountry: {
      country: false,
      addressLine1: false,
      addressLine2: false,
      city: false,
      currentCountry: true,
      phone: false,
      postalCode: false,
    },
    phone: {
      country: false,
      addressLine1: false,
      addressLine2: false,
      city: false,
      currentCountry: false,
      phone: true,
      postalCode: false,
    },
    postalCode: {
      country: false,
      addressLine1: false,
      addressLine2: false,
      city: false,
      currentCountry: false,
      phone: false,
      postalCode: true,
    },
  };
  const setLoadingStates = (name: keyof typeof loadingStates) => {
    const newState = loadingStates[name];
    setIsLoadingCountry(newState.country);
    setIsLoadingAddress1(newState.addressLine1);
    setIsLoadingAddress2(newState.addressLine2);
    setIsLoadingCity(newState.city);
    setIsLoadingDistrict(newState.currentCountry);
    setIsLoadingPhone(newState.phone);
    setIsLoadingZip(newState.postalCode);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone") {
      setValidAddressPhoneNumber(validAddressPhone(value));
    }
    switch (name) {
      case "country":
        setLoadingStates(name);
        break;
      case "addressLine2":
        setLoadingStates(name);
        break;
      case "addressLine1":
        setLoadingStates(name);
        break;
      case "city":
        setLoadingStates(name);
        break;
      case "currentCountry":
        setLoadingStates(name);
        break;
      case "phone":
        setLoadingStates(name);
        break;
      case "postalCode":
        setLoadingStates(name);
        break;

      default:
        break;
    }

    if (name in defaultContactInfo) {
      setContactInfo(name as keyof ContactInfo, value);
    } else {
      console.error(`${name} is not a valid key of ContactInfo.`);
    }
  };

  return (
    <Box component="section" className="initial-contact">
      <Container maxWidth="xl">
        <Grid
          marginBottom={5}
          container
          rowSpacing={4}
          columnSpacing={4}
          justifyContent="flex-end"
          alignItems="flex-end"
          rowGap={4}
        >
          <Grid
            id="countryBox"
            display="flex"
            container
            spacing={3}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid display="flex" item flexGrow={1}>
              <Typography
                // variant="h2"
                margin={isXs || isSm ? "auto" : ""}
                variant={isXs || isSm ? "h4" : "h2"}
                fontWeight="bold"
                textAlign="left"
              >
                Address
              </Typography>
            </Grid>
            {/* {showEdit ? (
              <Grid display="flex" item>
                <Button
                  component={Link}
                  to="/application/address"
                  variant="text"
                  color="primary"
                >
                  edit
                </Button>
              </Grid>
            ) : (
              <></>
            )} */}
          </Grid>
          <Grid
            display="flex"
            container
            flexDirection="column"
            marginLeft={isXs || isSm ? "30px" : ""}
            gap={4}
          >
            <Item>
              <Grid item display="flex" flexDirection="row" xs={12}>
                <Grid
                  item
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <FontAwesomeIcon icon={faHouse} size="xl" />
                </Grid>
                <Grid item display="flex" flexDirection="column">
                  <Typography
                    // variant="h5"
                    fontWeight="bold"
                    textAlign="left"
                    ml={2}
                    variant={isXs || isSm ? "h6" : "h5"}
                  >
                    Country Where You Live Today
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="row"
                xs={12}
                md={6}
                lg={4}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel shrink htmlFor="currentCountry-input">
                    Country
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <Autocomplete
                    // disabled={showEdit}
                    className={`input-auto ${!contactInfo.country && promptInfo ? "error-border" : ""}`}
                    noOptionsText="Type to search"
                    value={contactInfo.country}
                    onChange={handleCountrySelect1}
                    inputValue={countryInput1 || ""}
                    onInputChange={handleCountryInput1Change}
                    options={filteredCountries1}
                    filterOptions={filterOptions}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        placeholder="Country"
                        // sx={{ padding: "5px" }}
                        {...params}
                        variant="standard"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingCountries ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {/* {params.InputProps.endAdornment} */}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  {!contactInfo.country && promptInfo ? (
                    <Typography color="red" variant="body1" textAlign="left">
                      *Required field
                    </Typography>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Item>
          </Grid>
          <Grid
            display="flex"
            marginLeft={isXs || isSm ? "30px" : ""}
            container
            flexDirection="column"
            gap={4}
          >
            <Item>
              <Grid display="flex" container flexDirection="row">
                <Grid item display="flex" flexDirection="column" xs={12}>
                  <Grid
                    id="addressBox"
                    container
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Grid
                      item
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <FontAwesomeIcon icon={faMap} size="xl" />
                    </Grid>
                    <Grid item display="flex" flexDirection="column">
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        textAlign="left"
                        ml={2}
                      >
                        Mailing Address
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container display="flex" flexDirection="row">
                    <Grid item display="flex" flexDirection="column">
                      <Typography variant="body1" textAlign="left">
                        The U.S. Government will send more information to this
                        mailing address if you get chosen.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/*Form*/}
                <Grid item display="flex" flexDirection="row" xs={12}>
                  <Box
                    component="form"
                    noValidate
                    width="100%"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(1, 1fr)",
                        md: "repeat(2, 1fr)",
                      },
                      gap: 2,
                      rowGap: 4,
                    }}
                  >
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ gridColumn: { lg: "1/3", md: "1/3", xs: "1/2" } }}
                    >
                      <InputLabel shrink htmlFor="addressLine1-input">
                        Address line 1
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </InputLabel>
                      <CustomInputStyle
                        error={!contactInfo.addressLine1 && promptInfo}
                        // disabled={showEdit}
                        name="addressLine1"
                        placeholder="Address line 1"
                        fullWidth
                        value={contactInfo.addressLine1}
                        id="addressLine1-input"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoading && isLoadingAddress1 ? (
                              <CircularProgress size={20} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        }
                      />
                      {!contactInfo.addressLine1 && promptInfo ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          *Required field
                        </Typography>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ gridColumn: { lg: "1/3", md: "1/3", xs: "1/2" } }}
                    >
                      <InputLabel shrink htmlFor="addressLine2-input">
                        Address line 2
                      </InputLabel>
                      <CustomInputStyle
                        // disabled={showEdit}
                        name="addressLine2"
                        placeholder="Address line 2"
                        fullWidth
                        value={contactInfo.addressLine2}
                        id="addressLine2-input"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoading && isLoadingAddress2 ? (
                              <CircularProgress size={20} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink htmlFor="city-input">
                        City/Town
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </InputLabel>
                      <Autocomplete
                        // disabled={showEdit}

                        className={`input-auto ${!contactInfo.city && promptInfo ? "error-border" : ""}`}
                        noOptionsText="Type to search"
                        value={contactInfo.city}
                        onChange={handleCitySelect}
                        onInputChange={handleCityInputChange}
                        inputValue={selectedCity || ""}
                        options={cities}
                        filterOptions={filterOptions}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            placeholder="City/Town"
                            variant="standard"
                            {...params}
                            fullWidth
                            // disabled={!selectedCountry1}
                            InputProps={{
                              disableUnderline: true,
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingCities ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {/* {params.InputProps.endAdornment} */}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink htmlFor="district-input">
                        District/County/Province/State
                      </InputLabel>
                      <CustomInputStyle
                        // disabled={showEdit}
                        name="currentCountry"
                        placeholder="District/County/Province/State"
                        value={contactInfo.currentCountry}
                        id="district-input"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoading && isLoadingDistrict ? (
                              <CircularProgress size={20} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      fullWidth
                      style={{ marginTop: "-10px" }}
                    >
                      <label className="input-label">
                        Phone Number
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </label>

                      <div
                        className="focused"
                        style={{
                          borderRadius: "7px",
                          border:
                            !contactInfo.phone && promptInfo
                              ? "1px solid red"
                              : "1px solid #E0E3E7",
                        }}
                      >
                        <PhoneInput
                          // disabled={showEdit}
                          country="us"
                          placeholder="Phone Number"
                          value={contactInfo.phone}
                          onChange={(value) =>
                            handleChange({
                              target: {
                                name: "phone",
                                value: value?.toString(),
                              },
                            } as unknown as React.ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </div>
                      {!validAddressPhoneNumber && (
                        <Typography color="error">
                          Invalid phone number
                        </Typography>
                      )}
                      {!contactInfo.phone && promptInfo ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          *Required field
                        </Typography>
                      ) : (
                        ""
                      )}
                    </FormControl>

                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink htmlFor="postalCode-input">
                        Postal Code
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </InputLabel>
                      <CustomInputStyle
                        error={!contactInfo.postalCode && promptInfo}
                        // disabled={showEdit}
                        name="postalCode"
                        placeholder="Postal Code"
                        value={contactInfo.postalCode}
                        id="postalCode-input"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoading && isLoadingZip ? (
                              <CircularProgress size={20} />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        }
                      />
                      {!contactInfo.postalCode && promptInfo ? (
                        <Typography
                          color="red"
                          variant="body1"
                          textAlign="left"
                        >
                          *Required field
                        </Typography>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
