import "./_Hero.scss";
import { Box, Container } from "@mui/material";
import EligibilityCheck from "../../components/EligibilityCheck/EligibilityCheck";
import GoogleTranslate from "../../components/GoogleTranslatePopup";

export default function Hero() {
  return (
    <Box
      component="section"
      className="hero"
      height={{ sm: "600px", xs: 300, md: 600 }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden", // Prevent overflow outside of the box
      }}
    >
      <GoogleTranslate />
      <Container maxWidth="xl" component="section">
        <Box
          className="motto"
          width={{ xl: "50%", lg: "60%", md: "80%", sm: "100%", xs: "100%" }}
          height={{ xl: "100%", lg: "80%", md: "80%", sm: "100%", xs: "100%" }}
          maxHeight="800px"
          padding={{ xs: 2, sm: 3, md: 4, lg: 6 }}
          ml={{ xs: 0, sm: 0, md: "40px", lg: "40px", xl: "40px" }}
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <EligibilityCheck />
        </Box>
      </Container>
    </Box>
  );
}
