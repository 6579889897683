import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie"; // Ensure you have installed js-cookie

const SignUpForm: React.FC = () => {
  const { signup, logout } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [confirmEmail, setConfirmEmail] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  const getUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};

    // Extract UTM parameters from the URL
    [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_id",
      "utm_term",
      "utm_content",
    ].forEach((key) => {
      const value = urlParams.get(key);
      if (value) {
        utmParams[key] = value;
        localStorage.setItem(key, value);
        Cookies.set(key, value, { expires: 30 }); // Set the cookie to expire in 30 days
      }
    });

    // Extract userId from the URL
    const userID = urlParams.get("userID");
    if (userID) {
      localStorage.setItem("userID", userID);
      Cookies.set("userID", userID, { expires: 30 }); // Set the cookie to expire in 30 days
    }

    return { ...utmParams, userID };
  };

  useEffect(() => {
    logout();
    getUTMParameters()

    // Restore data from local storage on component mount
    const storedData = JSON.parse(
      localStorage.getItem("signUpFormData") || "{}",
    );
    if (storedData) {
      setFirstName(storedData.firstName || "");
      setLastName(storedData.lastName || "");
      setEmail(storedData.email || "");
      setPassword(storedData.password || "");
    }
  }, [logout, location.search]);

  useEffect(() => {
    // Save form data to local storage whenever it changes
    const formData = { firstName, lastName, email, password };
    localStorage.setItem("signUpFormData", JSON.stringify(formData));
  }, [firstName, lastName, email, password]);

  useEffect(() => {
    // Clear the form data from local storage when the route changes
    return () => {
      localStorage.removeItem("signUpFormData");
    };
  }, [location]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newErrors: { [key: string]: string } = {};

    if (!firstName) newErrors.firstName = "Please enter first name";
    if (!lastName) newErrors.lastName = "Please enter last name";
    if (!email) {
      newErrors.email = "Please enter an email address";
    } else if (!email.includes("@")) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!password) newErrors.password = "Please enter a password";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await signup(firstName, lastName, email, password);
      setConfirmEmail(true);
      // Clear the form data and UTM parameters/userID from local storage and cookies on success
      localStorage.removeItem("signUpFormData");
      localStorage.removeItem("utmParams");
      localStorage.removeItem("userID");
      Cookies.remove("utmParams");
      Cookies.remove("userID");
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrors({ ...newErrors, form: error.message });
      } else {
        setErrors({ ...newErrors, form: "An unexpected error occurred" });
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!confirmEmail ? (
          <>
            <Typography component="h1" variant="h5">
              Create Your Immigrateable Account
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
              id="sign-up-form"
            >
              <Grid display="flex" gap="20px">
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="email"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  type="text"
                  id="lastName"
                  autoComplete="current-password"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password}
              />
              {errors.form && (
                <Typography color="error" variant="body2">
                  {errors.form}
                </Typography>
              )}
              <Grid display="flex" alignItems="flex-start" mb={1}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Sign up for our newsletter to get all the latest news"
                  style={{ fontSize: "5px", padding: "0", letterSpacing: "0" }}
                />
              </Grid>
              <Typography textAlign="start" variant="body1">
                By creating an account you agree to our{" "}
                <Link
                  to="/account/termsandconditions"
                  target="_blank"
                  style={{ color: "black" }}
                >
                  Terms and Conditions
                </Link>{" "}
                and acknowledge that you have read our{" "}
                <Link
                  to="/account/privacy"
                  target="_blank"
                  style={{ color: "black" }}
                >
                  Privacy Policy
                </Link>
                .
              </Typography>
              <Button
                id="sign-up"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create Account
              </Button>
              <Grid display="flex" alignItems="flex-start">
                <Typography variant="body1">
                  Already have an Immigrateable account?{" "}
                  <Link
                    to="/account/login"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Log In
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h4">
              Welcome to{" "}
              <span style={{ color: "#C54B3A", fontWeight: 600 }}>
                Immigrateable
              </span>
              , please check your email address!
            </Typography>
            <Grid
              mt={2}
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faCircleCheck} size="3x" color="#C54B3A" />
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SignUpForm;
