import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

interface EligibilityCriteria {
  country: string;
  spouseCountry: string;
  parentsCountry: string;
  education: string;
  workExperience: string;
  highQualifiedJob: string;
  married: string;
  parentsTemporaryStay: string;
}

const EligibilityCheck: React.FC = () => {
  const [criteria, setCriteria] = useState<EligibilityCriteria>({
    country: "",
    spouseCountry: "",
    parentsCountry: "",
    education: "",
    workExperience: "",
    highQualifiedJob: "",
    married: "",
    parentsTemporaryStay: "",
  });

  const [visibleQuestion, setVisibleQuestion] = useState<string>("country");
  const [eligible, setEligible] = useState<boolean | null>(null);
  const [eligibleCountries, setEligibleCountries] = useState<string[]>([]);
  const [allCountries, setAllCountries] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    // Load countries data from JSON
    fetch("/Countries.json")
      .then((response) => response.json())
      .then((data) => {
        setEligibleCountries(data.eligibleCountries);
        setAllCountries(data.allCountries);
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to load countries data.");
        setLoading(false);
      });
  }, []);

  const handleSelectChange = (
    e: SelectChangeEvent<string>,
    key: keyof EligibilityCriteria,
  ) => {
    setCriteria({ ...criteria, [key]: e.target.value });
    setEligible(null);
  };

  const nextQuestion = (currentQuestion: string, nextQuestion: string) => {
    setVisibleQuestion(nextQuestion);
    if (nextQuestion === "eligibilityCheck") {
      checkEligibility();
    }
  };

  const checkEligibility = () => {
    const isCountryEligible = eligibleCountries.includes(criteria.country);
    const isSpouseCountryEligible = eligibleCountries.includes(
      criteria.spouseCountry,
    );
    const isParentsCountryEligible = eligibleCountries.includes(
      criteria.parentsCountry,
    );

    if (
      !isCountryEligible &&
      !isSpouseCountryEligible &&
      !isParentsCountryEligible
    ) {
      setEligible(false);
      return;
    }

    if (
      criteria.education === "highSchoolDegree" ||
      criteria.education === "university"
    ) {
      setEligible(true);
      return;
    }

    if (
      criteria.workExperience === "yes" ||
      criteria.highQualifiedJob === "yes"
    ) {
      setEligible(true);
      return;
    }

    setEligible(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        DV Lottery Eligibility Check
      </Typography>

      {visibleQuestion === "country" && (
        <>
          <Typography variant="h6" gutterBottom>
            What is your country of birth?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              noOptionsText="Type to search"
              value={criteria.country}
              onChange={(event, value) =>
                handleSelectChange(
                  { target: { value } } as SelectChangeEvent<string>,
                  "country",
                )
              }
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) =>
                setSearchQuery(newInputValue)
              }
              disableClearable
              options={allCountries}
              renderInput={(params) => (
                <TextField
                  placeholder="Country where you were born"
                  {...params}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "country",
                eligibleCountries.includes(criteria.country)
                  ? "education"
                  : "married",
              )
            }
            disabled={!criteria.country}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "married" && (
        <>
          <Typography variant="h6" gutterBottom>
            Are you married?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={criteria.married}
              onChange={(e) => handleSelectChange(e, "married")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select your answer</em>
              </MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "married",
                criteria.married === "yes"
                  ? "spouseCountry"
                  : "parentsTemporaryStay",
              )
            }
            disabled={!criteria.married}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "spouseCountry" && (
        <>
          <Typography variant="h6" gutterBottom>
            What is your spouse's country of birth?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              noOptionsText="Type to search"
              value={criteria.spouseCountry}
              onChange={(event, value) =>
                handleSelectChange(
                  { target: { value } } as SelectChangeEvent<string>,
                  "spouseCountry",
                )
              }
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) =>
                setSearchQuery(newInputValue)
              }
              disableClearable
              options={allCountries}
              renderInput={(params) => (
                <TextField
                  placeholder="Country where your spouse was born"
                  {...params}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "spouseCountry",
                eligibleCountries.includes(criteria.spouseCountry)
                  ? "education"
                  : "parentsTemporaryStay",
              )
            }
            disabled={!criteria.spouseCountry}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "parentsTemporaryStay" && (
        <>
          <Typography variant="h6" gutterBottom>
            Did your parents stay only for a short time in your country of
            birth, e.g. for studies or work?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={criteria.parentsTemporaryStay}
              onChange={(e) => handleSelectChange(e, "parentsTemporaryStay")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select your answer</em>
              </MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "parentsTemporaryStay",
                criteria.parentsTemporaryStay === "yes"
                  ? "parentsCountry"
                  : "notEligible",
              )
            }
            disabled={!criteria.parentsTemporaryStay}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "parentsCountry" && (
        <>
          <Typography variant="h6" gutterBottom>
            What is your parents' country of birth?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              noOptionsText="Type to search"
              value={criteria.parentsCountry}
              onChange={(event, value) =>
                handleSelectChange(
                  { target: { value } } as SelectChangeEvent<string>,
                  "parentsCountry",
                )
              }
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) =>
                setSearchQuery(newInputValue)
              }
              disableClearable
              options={allCountries}
              renderInput={(params) => (
                <TextField
                  placeholder="Country where your parent was born"
                  {...params}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "parentsCountry",
                eligibleCountries.includes(criteria.parentsCountry)
                  ? "education"
                  : "notEligible",
              )
            }
            disabled={!criteria.parentsCountry}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "education" && (
        <>
          <Typography variant="h6" gutterBottom>
            What is your highest degree of education?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={criteria.education}
              onChange={(e) => handleSelectChange(e, "education")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select your education level</em>
              </MenuItem>
              <MenuItem value="noHighSchool">No high school at all</MenuItem>
              <MenuItem value="someHighSchool">
                Some high school, no degree (less than 12 years of school)
              </MenuItem>
              <MenuItem value="highSchoolNoDegree">
                High school, no degree (12+ years of school)
              </MenuItem>
              <MenuItem value="highSchoolDegree">High school degree</MenuItem>
              <MenuItem value="university">University degree</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "education",
                [
                  "noHighSchool",
                  "someHighSchool",
                  "highSchoolNoDegree",
                ].includes(criteria.education)
                  ? "workExperience"
                  : "eligibilityCheck",
              )
            }
            disabled={!criteria.education}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "workExperience" && (
        <>
          <Typography variant="h6" gutterBottom>
            Within the last 5 years, have you worked for at least 2 years in an
            occupation requiring at least 2 years of training or experience?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={criteria.workExperience}
              onChange={(e) => handleSelectChange(e, "workExperience")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select your answer</em>
              </MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() =>
              nextQuestion(
                "workExperience",
                criteria.workExperience === "yes"
                  ? "eligibilityCheck"
                  : "highQualifiedJob",
              )
            }
            disabled={!criteria.workExperience}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "highQualifiedJob" && (
        <>
          <Typography variant="h6" gutterBottom>
            Have you worked more than 2 years in a highly qualified job in the
            past 5 years?
          </Typography>
          <FormControl fullWidth margin="normal">
            <Select
              value={criteria.highQualifiedJob}
              onChange={(e) => handleSelectChange(e, "highQualifiedJob")}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select your answer</em>
              </MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={() => nextQuestion("highQualifiedJob", "eligibilityCheck")}
            disabled={!criteria.highQualifiedJob}
            sx={{ bgcolor: "#C54B3A", borderRadius: 2, fontSize: 20 }}
          >
            Next
          </Button>
        </>
      )}

      {visibleQuestion === "eligibilityCheck" && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" color={eligible ? "#388E3C" : "#C54B3A"}>
            {eligible === null
              ? "Checking eligibility..."
              : eligible
                ? "Congratulations! You fulfill the requirements to join the Green Card Lottery!"
                : "Unfortunately, you are not eligible to join the Green Card Lottery."}
          </Typography>
          {eligible ? (
            <Button
              id="get-started-eligibility"
              component={Link}
              className="action-button-red"
              to="/application/personal-details"
              aria-label="Get Started"
              size="medium"
              variant="contained"
              sx={{
                borderRadius: 3,
                backgroundColor: "#C54B3A",
                px: 4,
                mt: 2,
                color: "white",
                "&:hover": {
                  backgroundColor: "#083657",
                },
              }}
            >
              Get Started
            </Button>
          ) : (
            ""
          )}
        </Box>
      )}

      {visibleQuestion === "notEligible" && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">
            Unfortunately, you are not eligible to join the Green Card Lottery.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EligibilityCheck;
