import React, { useContext, useEffect, useState } from "react";
import "./_FamilyDetails.scss";
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  InputAdornment,
  InputBase,
  InputLabel,
  styled,
  Switch,
  SwitchProps,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Item } from "../../../components/ItemComponent/Item.tsx";
import { Link } from "react-router-dom";
import {
  FamilyDetailsContext,
  defaultFamilyDetails,
} from "../../../context/FamilyDetailsContext";
import axios from "axios";
import { PersonalInfoContext } from "../../../context/PersonalInfoContext.tsx";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
    "& label": {
      marginLeft: 0,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

type Child = {
  firstname: string;
  middlename: string;
  lastname: string;
  dob: string;
  photo: string;
  id?: number;
};

const CustomInputStyle = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderRadius: 10,
    borderColor: error
      ? theme.palette.error.main
      : theme.palette.mode === "light"
        ? "#E0E3E7"
        : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(
        error
          ? "rgba(255, 0, 0, 0.25) 0 0 0 0.2rem"
          : theme.palette.primary.main,
        0.25,
      )} 0 0 0 0.2rem`,
    },
  },
}));

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean; // Define error prop as optional boolean
}

const CustomInputStyleImg = styled("input")<CustomInputProps>(
  ({ theme, error }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderRadius: 10,
    borderColor: error
      ? theme.palette.error.main
      : theme.palette.mode === "light"
        ? "#E0E3E7"
        : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(
        error
          ? "rgba(255, 0, 0, 0.25) 0 0 0 0.2rem"
          : theme.palette.primary.main,
        0.25,
      )} 0 0 0 0.2rem`,
    },
  }),
);

interface childrenInfoInterface {
  id: number;
  firstname: string;
  middlename: string;
  lastname: string;
  dob: string;
  photo: string;
}

type FamilyProps = {
  showEdit: boolean;
  promptInfo: boolean;
};

export default function FamilyDetails({ showEdit, promptInfo }: FamilyProps) {
  const [hasSpouse, setHasSpouse] = useState(false);
  const {
    familyDetails,
    setFamilyDetails,
    setChildrenInfo,
    resetSpouseDetails,
    isLoading,
    addChild,
    removeChild,
  } = useContext(FamilyDetailsContext);
  const { personalInfo, setPersonalInfo } = useContext(PersonalInfoContext);
  const [familyDetailsImgSrc, setFamilyDetailsImgSrc] = useState<
    string | undefined
  >(undefined);
  const [childBtnRef, setChildBtnRef] = useState<HTMLDivElement | null>(null);
  const [firstLoad, setFirstLoad] = useState(false);

  const [isLoadingSpouseFirstName, setIsLoadingSpouseFirstName] =
    useState(false);
  const [isLoadingSpouseMiddleName, setIsLoadingSpouseMiddleName] =
    useState(false);
  const [isLoadingSpouseLastName, setIsLoadingSpouseLastName] = useState(false);
  const [isLoadingSpouseDob, setIsLoadingSpouseDob] = useState(false);
  const [isLoadingSpousePhoto, setIsLoadingSpousePhoto] = useState(false);

  const [isLoadingChildFirstName, setIsLoadingChildFirstName] = useState(false);
  const [isLoadingChildMiddleName, setIsLoadingChildMiddleName] =
    useState(false);
  const [isLoadingChildLastName, setIsLoadingChildLastName] = useState(false);
  const [isLoadingChildDob, setIsLoadingChildDob] = useState(false);
  const [isLoadingChildPhoto, setIsLoadingChildPhoto] = useState(false);

  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.down("md"));
  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const formatDateForInput = (dateString: string): string => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleMarriedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setHasSpouse(checked);
    if (!checked) {
      resetSpouseDetails();
    }
    setFamilyDetails("have_spouse", checked);
  };

  useEffect(() => {
    const allowedPaths = ["welcome", "address", "family-details", "review"];
    const url = new URL(window.location.href);
    const applicationId = personalInfo.id;
    const currentPath = url.pathname;
    const pathSegments = currentPath.split("/").filter((segment) => segment);

    const applicationsIndex = pathSegments.indexOf("application");

    if (applicationsIndex !== -1 && applicationId) {
      // Ensure the applicationId is right after "application"
      if (pathSegments[applicationsIndex + 1] !== applicationId) {
        pathSegments.splice(applicationsIndex + 1, 0, applicationId);
      }

      // Retain only allowed paths after the applicationId
      const newSegments = pathSegments.slice(0, applicationsIndex + 2);
      const remainingSegments = pathSegments.slice(applicationsIndex + 2);
      for (const segment of remainingSegments) {
        if (allowedPaths.includes(segment)) {
          newSegments.push(segment);
        } else {
          break;
        }
      }

      const newPath = "/" + newSegments.join("/");
      const newUrl = `${url.origin}${newPath}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [personalInfo.id]);

  // const hasFamilyDetails = (): boolean => {
  //   const familyDetails = localStorage.getItem("familyDetails");
  //   if (familyDetails) {
  //     const parsedDetails = JSON.parse(familyDetails);
  //     if (Array.isArray(parsedDetails.children)) {
  //       // console.log(parsedDetails.children);
  //       return true;
  //     } else {
  //       parsedDetails.children = [];
  //       localStorage.setItem("familyDetails", JSON.stringify(parsedDetails));
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // Example usage
  // const result = hasFamilyDetails();
  // console.log(result);

  useEffect(() => {
    if (childBtnRef && firstLoad)
      childBtnRef.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [familyDetails.children.length, childBtnRef, firstLoad]);

  // console.log(childBtnRef);

  // console.log(familyDetails.children.length);

  useEffect(() => {
    if (
      familyDetails.spouse.firstname ||
      familyDetails.spouse.middlename ||
      familyDetails.spouse.lastname ||
      familyDetails.spouse.dob ||
      familyDetails.spouse.photo
    ) {
      setHasSpouse(true);
    }
  }, [familyDetails.spouse]);

  const familyDetailsReadUrl = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFamilyDetailsImgSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    console.log(name, value);

    switch (name) {
      case "firstname":
        setIsLoadingSpouseFirstName(true);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        break;

      case "middlename":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(true);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);

        break;
      case "lastname":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(true);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        break;
      case "dob":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(true);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        const year = value.split("-")[0];
        if (value.split("-")[0].length !== 4) {
          console.error("Year must be 4 digits");
          return;
        }
        break;
      // case "photo":
      //   setIsLoadingSpouseFirstName(false);
      //   setIsLoadingSpouseLastName(false);
      //   setIsLoadingSpouseMiddleName(false);
      //   setIsLoadingSpouseDob(false);
      //   setIsLoadingSpousePhoto(true);
      //   setIsLoadingChildFirstName(false);
      //   setIsLoadingChildLastName(false);
      //   setIsLoadingChildMiddleName(false);
      //   setIsLoadingChildDob(false);
      //   setIsLoadingChildPhoto(false);
      //   break;
      default:
        break;
    }
    if (files) {
      const file = files[0];
      if (file.type !== "image/jpeg") {
        console.log("Only JPG images are allowed.");
        return;
      }
      familyDetailsReadUrl(files);
      const csrfToken = localStorage.getItem("csrfToken");
      if (e.target.name === "photo") {
        const handlePhotoUrl = async (): Promise<any | undefined> => {
          try {
            const formData = new FormData();
            formData.append("image", files[0]);
            const applicationId = personalInfo.id;
            formData.append("applicationId", applicationId);
            formData.append("type", "spouse");
            const response = await axios.post(
              `${import.meta.env.VITE_PHOTO_SERVER_URL}/upload-and-crop`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Csrf-Token": csrfToken,
                },
                withCredentials: true,
              },
            );
            console.log(
              `File uploaded successfully. URL: ${response.data.Location}`,
            );
            return response.data.imageUrl;
          } catch (error) {
            console.error("Error uploading file:", error);
            console.log("Error uploading file. Please try again.");
          }

          // return 'https://immigrateable.nyc3.cdn.digitaloceanspaces.com/Screenshot%202023-08-01%20at%206.37.56%20PM.png';
        };

        (async () => {
          const photoUrl = await handlePhotoUrl();
          // Here you can use photoUrl as needed
          setFamilyDetails("spouse", {
            ...familyDetails.spouse,
            photo: photoUrl,
          });
        })();
      }
    } else {
      if (name in defaultFamilyDetails.spouse) {
        setFamilyDetails("spouse", { ...familyDetails.spouse, [name]: value });
      } else {
        console.error(`${name} Family details context error`);
      }
    }
  };

  const handleChildChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    childId: number,
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstname":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(true);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        break;
      case "lastname":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(true);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        break;
      case "middlename":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(true);
        setIsLoadingChildDob(false);
        setIsLoadingChildPhoto(false);
        break;
      case "dob":
        setIsLoadingSpouseFirstName(false);
        setIsLoadingSpouseLastName(false);
        setIsLoadingSpouseMiddleName(false);
        setIsLoadingSpouseDob(false);
        setIsLoadingSpousePhoto(false);
        setIsLoadingChildFirstName(false);
        setIsLoadingChildLastName(false);
        setIsLoadingChildMiddleName(false);
        setIsLoadingChildDob(true);
        setIsLoadingChildPhoto(false);
        break;
      default:
        break;
    }

    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      const files = e.target.files;
      if (files && files.length > 0) {
        const csrfToken = localStorage.getItem("csrfToken");
        const handlePhotoUrl = async (): Promise<string | undefined> => {
          try {
            const formData = new FormData();
            formData.append("image", files[0]);
            const applicationId = personalInfo.id;
            formData.append("applicationId", applicationId);
            formData.append("type", `child${childId}`);
            const response = await axios.post(
              `${import.meta.env.VITE_PHOTO_SERVER_URL}/upload-and-crop`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Csrf-Token": csrfToken,
                },
                withCredentials: true,
              },
            );
            console.log(
              `File uploaded successfully. URL: ${response.data.Location}`,
            );
            return response.data.imageUrl;
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        };

        const photoUrl = await handlePhotoUrl();
        setChildrenInfo(childId, "photo", photoUrl!);
      }
    } else {
      setChildrenInfo(childId, name as keyof childrenInfoInterface, value);
    }
  };

  return (
    <Box className="initial-contact" marginLeft={isXs || isSm ? "30px" : ""}>
      <Container maxWidth="xl">
        <Grid
          container
          rowSpacing={4}
          columnSpacing={4}
          justifyContent="flex-end"
          alignItems="flex-end"
          rowGap={4}
          columnGap={4}
        >
          <Grid
            display="flex"
            container
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Grid
              display="flex"
              // mt={!hasSpouse ? 7 : 0}
              item
              flexGrow={1}
            >
              <Typography
                // variant="h2"
                margin={isXs || isSm ? "auto" : ""}
                variant={isXs || isSm ? "h4" : "h2"}
                fontWeight="bold"
                textAlign="left"
              >
                Family Details
              </Typography>
            </Grid>
            {/* {showEdit ? (
              <Grid display="flex" item>
                <Button
                  component={Link}
                  to="/application/family-details"
                  variant="text"
                  color="primary"
                >
                  edit
                </Button>
              </Grid>
            ) : (
              <></>
            )} */}
          </Grid>
          <Grid
            display="flex"
            container
            justifyContent="flex-end"
            flexDirection="row"
            alignItems="flex-end"
          >
            <Grid container display="flex" flexDirection="row">
              <Grid item xs={12} md={6} display="flex">
                <FormControlLabel
                  className="switch"
                  control={
                    <IOSSwitch
                      checked={hasSpouse}
                      onChange={handleMarriedChange}
                      sx={{ m: 1 }}
                    />
                  }
                  label={hasSpouse ? "I am married" : "I am not married"}
                  labelPlacement="end"
                  sx={{ marginLeft: 0 }}
                />
              </Grid>
            </Grid>

            {
              <Grow
                mountOnEnter
                unmountOnExit
                in={hasSpouse}
                style={{
                  transformOrigin: "0 0 0",
                }}
              >
                <Item>
                  <Grid
                    id="spouseBox"
                    display="flex"
                    container
                    flexDirection="row"
                    mt={2}
                  >
                    <Grid item display="flex" flexDirection="row" xs={12}>
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <FontAwesomeIcon icon={faUser} size="xl" />
                      </Grid>
                      <Grid item display="flex" flexDirection="column">
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          textAlign="left"
                          ml={2}
                        >
                          Spouse
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="row"
                      xs={12}
                      mt={4}
                    >
                      <Grid item display="flex" flexDirection="row" xs={12}>
                        <Box
                          component="form"
                          noValidate
                          width="100%"
                          sx={{
                            display: "grid",
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              md: "repeat(2, 1fr)",
                              lg: "repeat(3, 1fr)",
                            },
                            gap: 2,
                            rowGap: 4,
                          }}
                        >
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="spouseFirstname-input">
                              First name
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <CustomInputStyle
                              // disabled={showEdit}
                              error={
                                !familyDetails.spouse.firstname && promptInfo
                              }
                              name="firstname"
                              placeholder="Spouse First name"
                              fullWidth
                              value={familyDetails.spouse.firstname}
                              id="spouseFirstname-input"
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {isLoading && isLoadingSpouseFirstName ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    ""
                                  )}
                                </InputAdornment>
                              }
                            />
                            {!familyDetails.spouse.firstname && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="spouseMiddlename-input">
                              Middle name
                            </InputLabel>
                            <CustomInputStyle
                              // disabled={showEdit}
                              name="middlename"
                              placeholder="Middle name"
                              fullWidth
                              value={familyDetails.spouse.middlename}
                              id="spouseMiddlename-input"
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {isLoading && isLoadingSpouseMiddleName ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    ""
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="spouseLastname-input">
                              Last name
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <CustomInputStyle
                              error={
                                !familyDetails.spouse.lastname && promptInfo
                              }
                              // disabled={showEdit}
                              name="lastname"
                              placeholder="Last name"
                              fullWidth
                              value={familyDetails.spouse.lastname}
                              id="spouseLastname-input"
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {isLoading && isLoadingSpouseLastName ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    ""
                                  )}
                                </InputAdornment>
                              }
                            />
                            {!familyDetails.spouse.lastname && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                          <FormControl variant="standard">
                            <InputLabel shrink htmlFor="spouseDob-input">
                              Date of birth
                              <Typography component="span" color="error">
                                *
                              </Typography>
                            </InputLabel>
                            <CustomInputStyle
                              error={!familyDetails.spouse.dob && promptInfo}
                              // disabled={showEdit}
                              name="dob"
                              type="date"
                              placeholder="Date of birth"
                              fullWidth
                              value={
                                familyDetails.spouse.dob
                              }
                              id="spouseDob-input"
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end">
                                  {isLoading && isLoadingSpouseDob ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    ""
                                  )}
                                </InputAdornment>
                              }
                            />
                            {!familyDetails.spouse.dob && promptInfo ? (
                              <Typography
                                color="red"
                                variant="body1"
                                textAlign="left"
                              >
                                *Required field
                              </Typography>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid display="flex" container flexDirection="row" mt={4}>
                      <Grid item display="flex" flexDirection="row" xs={12}>
                        <Grid
                          item
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <FontAwesomeIcon icon={faCamera} size="xl" />
                        </Grid>
                        <Grid item display="flex" flexDirection="column">
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="left"
                            ml={2}
                          >
                            Photograph
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        xs={12}
                        md={6}
                        lg={4}
                      >
                        <FormControl variant="standard" fullWidth>
                          <InputLabel shrink htmlFor="photo-input">
                            Upload your spouse photograph
                            <Typography component="span" color="error">
                              *
                            </Typography>
                          </InputLabel>
                          <CustomInputStyleImg
                            error={!familyDetails.spouse.photo && promptInfo}
                            accept="image/jpeg"
                            // disabled={showEdit}
                            name="photo"
                            placeholder="Photograph"
                            // fullWidth
                            type="file"
                            id="photo-input"
                            onChange={handleChange}
                            // endAdornment={
                            //   <InputAdornment position="end">
                            //     {isLoading && isLoadingSpousePhoto ? (
                            //       <CircularProgress size={20} />
                            //     ) : (
                            //       ""
                            //     )}
                            //   </InputAdornment>
                            // }
                          />
                          {
                            familyDetails.spouse.photo && (
                              <Box mt={2}>
                                <Typography variant="body1" display="flex">
                                  Preview:
                                </Typography>
                                <img
                                  src={familyDetails.spouse.photo}
                                  alt="Spouse Photograph"
                                  style={{
                                    maxWidth: "100%",

                                    marginTop: "10px",
                                  }}
                                />
                              </Box>
                            )
                            // (
                            //   <img
                            //     src={familyDetails.spouse.photo}
                            //     alt="Spouse Photograph"
                            //     style={{ marginTop: "10px", maxWidth: "100%" }}
                            //   />
                            // )
                          }
                          {!familyDetails.spouse.photo && promptInfo ? (
                            <Typography
                              color="red"
                              variant="body1"
                              textAlign="left"
                            >
                              *Required field
                            </Typography>
                          ) : (
                            ""
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Item>
              </Grow>
            }
          </Grid>
          <Grid display="flex" container flexDirection="row">
            <Grid container gap={4}>
              {familyDetails.children.length > 0 ? (
                familyDetails.children.map((child, index) => (
                  <Grow
                    id={`child${index}Box`}
                    mountOnEnter
                    unmountOnExit
                    in={familyDetails.children.length > 0}
                    key={child.id}
                    style={{
                      transformOrigin: "0 0 0",
                    }}
                  >
                    <Grid item xs={12}>
                      <Item>
                        <Grid
                          display="flex"
                          container
                          flexDirection="row"
                          mt={2}
                        >
                          <Grid item display="flex" flexDirection="row" xs={12}>
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              <FontAwesomeIcon icon={faUser} size="xl" />
                            </Grid>
                            <Grid
                              item
                              display="flex"
                              flexDirection="column"
                              alignSelf="flex-end"
                              flexGrow={1}
                            >
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                textAlign="left"
                                ml={2}
                              >
                                Child #{index + 1}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              display="flex"
                              flexDirection="column"
                              alignSelf="flex-end"
                            >
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => removeChild(child.id!)}
                              >
                                Remove
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            display="flex"
                            flexDirection="row"
                            xs={12}
                            mt={4}
                          >
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              xs={12}
                            >
                              <Box
                                component="form"
                                noValidate
                                width="100%"
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: {
                                    xs: "repeat(1, 1fr)",
                                    md: "repeat(2, 1fr)",
                                    lg: "repeat(3, 1fr)",
                                  },
                                  gap: 2,
                                  rowGap: 4,
                                }}
                              >
                                <FormControl variant="standard">
                                  <InputLabel
                                    shrink
                                    htmlFor="childFirstname-input"
                                  >
                                    First name
                                    <Typography component="span" color="error">
                                      *
                                    </Typography>
                                  </InputLabel>
                                  <CustomInputStyle
                                    error={!child.firstname && promptInfo}
                                    // disabled={showEdit}
                                    name="firstname"
                                    placeholder="First name"
                                    fullWidth
                                    value={child.firstname}
                                    id="childFirstname-input"
                                    onChange={(e) => {
                                      handleChildChange(e, child.id!);
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {isLoading &&
                                        isLoadingChildFirstName ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                  {!child.firstname && promptInfo ? (
                                    <Typography
                                      color="red"
                                      variant="body1"
                                      textAlign="left"
                                    >
                                      *Required field
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </FormControl>
                                <FormControl variant="standard">
                                  <InputLabel
                                    shrink
                                    htmlFor="childMiddlename-input"
                                  >
                                    Middle name
                                  </InputLabel>
                                  <CustomInputStyle
                                    // disabled={showEdit}
                                    name="middlename"
                                    placeholder="Middle name"
                                    fullWidth
                                    value={child.middlename}
                                    id="childMiddlename-input"
                                    onChange={(e) =>
                                      handleChildChange(e, child.id!)
                                    }
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {isLoading &&
                                        isLoadingChildMiddleName ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                                <FormControl variant="standard">
                                  <InputLabel
                                    shrink
                                    htmlFor="childLastname-input"
                                  >
                                    Last name
                                    <Typography component="span" color="error">
                                      *
                                    </Typography>
                                  </InputLabel>
                                  <CustomInputStyle
                                    error={!child.lastname && promptInfo}
                                    // disabled={showEdit}
                                    name="lastname"
                                    placeholder="Last name"
                                    fullWidth
                                    value={child.lastname}
                                    id="childLastname-input"
                                    onChange={(e) =>
                                      handleChildChange(e, child.id!)
                                    }
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {isLoading && isLoadingChildLastName ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                  {!child.lastname && promptInfo ? (
                                    <Typography
                                      color="red"
                                      variant="body1"
                                      textAlign="left"
                                    >
                                      *Required field
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </FormControl>
                                <FormControl variant="standard">
                                  <InputLabel shrink htmlFor="childDob-input">
                                    Date of birth
                                    <Typography component="span" color="error">
                                      *
                                    </Typography>
                                  </InputLabel>
                                  <CustomInputStyle
                                    error={!child.dob && promptInfo}
                                    // disabled={showEdit}
                                    name="dob"
                                    type="date"
                                    placeholder="Date of birth"
                                    fullWidth
                                    value={child.dob}
                                    id="childDob-input"
                                    onChange={(e) =>
                                      handleChildChange(e, child.id!)
                                    }
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {isLoading && isLoadingChildDob ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          ""
                                        )}
                                      </InputAdornment>
                                    }
                                  />
                                  {!child.dob && promptInfo ? (
                                    <Typography
                                      color="red"
                                      variant="body1"
                                      textAlign="left"
                                    >
                                      *Required field
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid
                            display="flex"
                            container
                            flexDirection="row"
                            mt={4}
                          >
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              xs={12}
                            >
                              <Grid
                                item
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                              >
                                <FontAwesomeIcon icon={faCamera} size="xl" />
                              </Grid>
                              <Grid item display="flex" flexDirection="column">
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  textAlign="left"
                                  ml={2}
                                >
                                  Photograph
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              display="flex"
                              flexDirection="row"
                              xs={12}
                              md={6}
                              lg={4}
                            >
                              <FormControl variant="standard" fullWidth>
                                <InputLabel
                                  shrink
                                  htmlFor={`childPhoto-input-${child.id}`}
                                >
                                  Upload child photograph
                                  <Typography component="span" color="error">
                                    *
                                  </Typography>
                                </InputLabel>
                                <CustomInputStyleImg
                                  error={!child.photo && promptInfo}
                                  accept="image/jpeg"
                                  // disabled={showEdit}
                                  name={`photo-${child.id}`}
                                  placeholder="Photograph"
                                  // fullWidth
                                  type="file"
                                  id={`childPhoto-input-${child.id}`}
                                  onChange={(e) =>
                                    handleChildChange(e, child.id!)
                                  }
                                />
                                {
                                  child.photo && (
                                    <Box mt={2}>
                                      <Typography
                                        variant="body1"
                                        display="flex"
                                      >
                                        Preview:
                                      </Typography>
                                      <img
                                        src={child.photo}
                                        alt={`Child ${index + 1} Photograph`}
                                        style={{
                                          maxWidth: "100%",
                                          // maxHeight: "350px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </Box>
                                  )

                                  //   <img
                                  //     src={child.photo}
                                  //     alt={`Child ${index + 1} Photograph`}
                                  //     style={{
                                  //       marginTop: "10px",
                                  //       maxWidth: "100%",
                                  //     }}
                                  //   />
                                  // )
                                }
                                {!child.photo && promptInfo ? (
                                  <Typography
                                    color="red"
                                    variant="body1"
                                    textAlign="left"
                                  >
                                    *Required field
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  </Grow>
                ))
              ) : (
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  mt={4}
                  justifyContent="center"
                >
                  <Typography variant="h5" fontWeight="bold" textAlign="center">
                    No children added
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              display="flex"
              container
              flexDirection="row"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                alignItems="flex-end"
                ref={(ref) => setChildBtnRef(ref)}
              >
                <Button
                  startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                  variant="text"
                  color="primary"
                  onClick={addChild}
                >
                  Add child
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
