import React from "react";
import { Typography, Container, Grid, Box, Link } from "@mui/material";

interface Obj {
  title: string;
  text: string;
}

const RefundPolicy: React.FC = () => {
  const createObject: Obj[] = [
    {
      title: "Service Fees",
      text: "All fees for our services are listed on our website and must be paid in full before we begin processing your application. This ensures that we can allocate the necessary resources to provide you with the highest level of service and support throughout your application process.",
    },
    {
      title: "Refund Policy",
      text: "Once the application process has started, fees are generally non-refundable. However, exceptions to this policy are as follows:",
    },
  ];

  return (
    <Container>
      <Grid container direction="column" textAlign="left">
        <Grid item>
          <Typography
            textAlign="center"
            variant="h4"
            fontWeight="bold"
            className="privacy"
            style={{ marginBottom: "16px" }}
          >
            Fees and Refund Policy
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            {createObject.map((item, index) => (
              <Grid key={index} item marginBottom="15px">
                <Typography
                  fontWeight="bold"
                  fontSize="20px"
                  marginBottom="10px"
                >
                  {item.title}
                </Typography>
                <Typography>{item.text}</Typography>
              </Grid>
            ))}
          </Box>
        </Grid>
        <Grid item>
          <Box my={3}>
            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginBottom="10px"
              gutterBottom
            >
              1. Errors on Our Part:
            </Typography>
            <Typography variant="body1" paragraph>
              If your application is rejected due to an error made by
              Immigrateable, you may be eligible for a refund. This includes
              mistakes in data entry, incorrect submission of documents, or any
              other errors directly attributable to our team.
            </Typography>

            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginBottom="10px"
              gutterBottom
            >
              2. Service Non-Delivery:
            </Typography>
            <Typography variant="body1" paragraph>
              If we fail to deliver the agreed-upon services, you may be
              entitled to a refund. Non-delivery of services includes failure to
              submit your application to the U.S. authorities or failure to
              provide the services outlined on our website.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Typography fontWeight="bold" fontSize="20px" marginBottom="10px">
              Refund Request Process
            </Typography>
            <Typography>
              To request a refund, please follow these steps:
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box my={3}>
            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginBottom="10px"
              gutterBottom
            >
              1. Submit a Written Request:
            </Typography>
            <Typography variant="body1" paragraph>
              Provide a detailed explanation of the issue, including your full
              name, contact information, and the specifics of the error or
              service non-delivery.
            </Typography>

            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginBottom="10px"
              gutterBottom
            >
              2. Request Review:
            </Typography>
            <Typography variant="body1" paragraph>
              Our customer service team will review your request. If the issue
              is found to be a result of our error or service non-delivery, we
              will process a refund.
            </Typography>

            <Typography
              fontWeight="bold"
              fontSize="20px"
              marginBottom="10px"
              gutterBottom
            >
              3. Refund Issuance:
            </Typography>
            <Typography variant="body1" paragraph>
              Approved refunds will be issued within 30 days of approval. The
              refund will be processed through the original payment method used.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <Typography fontWeight="bold" fontSize="20px" marginBottom="10px">
              Contact Information
            </Typography>
            <Typography>
              For any questions or concerns regarding our Fees and Refund
              Policy, please contact us at
              <Link href="mailto:support@immigrateable.com">
                support@immigrateable.com
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RefundPolicy;
