export default async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  const csrfToken = localStorage.getItem('csrfToken'); // Assuming CSRF token is also stored in local storage

  console.log(refreshToken);
  
  if (!refreshToken) {
    console.error('No refresh token found in local storage');
    return;
  }

  try {
    const response = await fetch(`${import.meta.env.VITE_API_SERVER_URL}/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken || '',
      },
      credentials: 'include',
      body: JSON.stringify({ token: refreshToken }),
    });

    if (!response.ok) {
      throw new Error(`Failed to refresh token: ${response.statusText}`);
    }

    const data = await response.json();
    const newAccessToken = data.accessToken;

    if (newAccessToken) {
      localStorage.setItem('authToken', newAccessToken);
      console.log('Access token updated successfully');
    } else {
      console.error('No access token received from server');
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
  }
}
