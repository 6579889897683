import React from 'react'
import { Link } from "react-router-dom";
import "./_Privacy.scss"

import {
  Container,
  Grid,
  Typography
} from "@mui/material";

const Privacy = () => {
  return (
    <div>
        <Container>
          <Grid 
             textAlign="left">
                <Typography textAlign="center"  variant="h4" fontWeight="bold" className="privacy" style={{marginBottom: "16px"}}>Privacy</Typography>
                <Typography paragraph style={{fontSize: "17px"}}>
                 At Immigrateable, we are dedicated to safeguarding your privacy and ensuring the security of your personal data. This privacy policy outlines how we collect, use, and protect your information in compliance with applicable laws.
              </Typography>

              <Typography paragraph marginBottom="30px">
                  <Typography variant="h5">Data Collection</Typography>
                  <Typography variant="body2" paragraph sx={{ m: 2 }} style={{ fontSize: '16px' }}>
                    <strong style={{ fontSize: '20px' }}>1.Personal Information:</strong> <br /> We collect personal details such as your name, email address, and contact information during registration or when you utilize our services.
                  </Typography>
                  <Typography variant="body2" paragraph sx={{ m: 2 }} style={{ fontSize: '16px' }}>
                    <strong style={{ fontSize: '20px' }}>2.Technical Information:</strong> <br /> We automatically gather technical data including your IP address, browser type, and operating system when you visit our website.
                  </Typography>
                  <Typography variant="body2" paragraph sx={{ m: 2 }} style={{ fontSize: '16px' }}>
                    <strong style={{ fontSize: '20px' }}>3.Cookies:</strong> <br /> We use cookies and similar technologies to enhance your browsing experience and collect data about your usage patterns.
                  </Typography>
              </Typography>

                <Typography paragraph marginBottom="30px">
                  <Typography fontWeight="bold" style={{ fontSize: '20px' }}>
                    Use data Your data is used for the following purposes:
                  </Typography>
                    <ul>
                      <li>Application Processing: To manage and process your DV Lottery application.</li>
                      <li>Service Provision: To offer personalized services and customer support.</li>
                      <li>Communication: To send important updates and information about your application.</li>
                      <li>Service Improvement: To analyze user feedback and improve our services.</li>
                    </ul>
                </Typography>

                <Typography paragraph marginBottom="30px">
                    <Typography variant="h5" style={{marginBottom: "16px"}}>Data Sharing</Typography>
                    <Typography>Data Sharing We do not disclose your personal information to third parties for advertising purposes without your explicit consent. We may share your data with trusted partners and service providers who assist in operating our website and delivering our services, ensuring they adhere to strict data protection standards.</Typography>
                </Typography>

                <Typography paragraph marginBottom="30px">
                    <Typography>Data Security We employ stringent security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Our data processing practices comply with relevant legal requirements, including the General Data Protection Regulation (GDPR) for users within the European Union.</Typography>
                </Typography>

                <Typography paragraph marginBottom="30px">
                  <Typography fontWeight="bold" style={{ fontSize: '20px' }}>
                    Your Rights You are entitled to:
                  </Typography>
                    <ul>
                      <li>Access: Request access to your personal data.</li>
                      <li>Rectification: Correct any inaccuracies in your data..</li>
                      <li>Deletion: Request the deletion of your personal data.</li>
                      <li>Restriction: Restrict the processing of your data..</li>
                      <li>Objection: Object to the processing of your data.</li>
                      <li>Withdrawal of Consent: Withdraw your consent for data processing at any time.</li>
                    </ul>
                </Typography>

                <Typography paragraph marginBottom="30px">
                  <Typography>Cookies Policy Our website uses cookies to personalize content, analyze traffic, and improve user experience. You can manage your cookie preferences through your browser settings. Detailed information on our use of cookies is available on our Cookies Policy page.</Typography>
                </Typography>

                <Typography paragraph marginBottom="30px">
                  <Typography>Third-Party Services We utilize third-party services, such as Google Analytics, to analyze website usage. These third-party service providers may collect data about your interactions with our website under their own privacy policies.</Typography>
                </Typography>

                <Typography paragraph marginBottom="30px">
                  <Typography>Changes to This Policy We reserve the right to update this privacy policy periodically. Any changes will be posted on this page with an updated revision date.</Typography>
                </Typography>

                <Typography paragraph>
                  <Typography variant="h5" style={{marginBottom: "16px"}}>Contact Information</Typography>
                  <Typography>
                    For any questions or concerns regarding our privacy policy, please contact us at <Link to="mailto:support@immigrateable.com">support@immigrateable.com</Link>
                  </Typography>
                </Typography>
          </Grid>
        </Container>
    </div>
  )
}

export default Privacy