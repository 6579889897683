import React from "react";
import {
  Link,
  //  useNavigate
} from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// Ensure useAuth includes signup

const ResetPasswordForm: React.FC = () => {
  // useNavigate();
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [resetText, setResetText] = React.useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(""); // Clear any previous errors

    if (!email) {
      setError("Please enter an email");
      return;
    }
    if (!email.includes("@")) {
      setError("Please enter a valid email address");
      return;
    }
    const csrfToken = localStorage.getItem("csrfToken");
    const headers = {
      "Content-Type": "application/json",
      "CSRF-Token": csrfToken || "",
    } as HeadersInit;
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_SERVER_URL}/${import.meta.env.VITE_RESET_PASSWORD_URL}`,
        {
          method: "POST",
          headers: headers,
          credentials: "include",
          body: JSON.stringify({ email }),
        },
      );
      // console.log(csrfToken);

      if (response.ok) {
        // Handle successful response
        setResetText(true);
        console.log("Password reset email sent successfully");
      } else {
        // Handle error response
        const errorData = await response.json();
        setError(errorData.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!resetText ? (
          <>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              id="reset-password-form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              <Grid display="flex" width={isXs || isSm ? "270px" : "350px"}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={!!error}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {error && (
                <Grid container alignItems="center">
                  <Typography display="flex" color="error" variant="body1">
                    {error}.
                  </Typography>
                  {error === "Email not registered" && (
                    <Typography display="flex" variant="body1" ml={1}>
                      <Link
                        to="/account/signup"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        Sign up here
                      </Link>
                    </Typography>
                  )}
                </Grid>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Reset Password
              </Button>
              <Grid display="flex" alignItems="flex-start">
                <Typography variant="body1">
                  Remember Password?{" "}
                  <Link
                    to="/account/login"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Back to Log in
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5">
              We've sent a password reset email, please check your inbox.
            </Typography>
            <Grid
              mt={2}
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faCircleCheck} size="3x" color="#C54B3A" />
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ResetPasswordForm;
