import { Link } from "react-router-dom"
import {
    Typography
  } from "@mui/material";
const Terms = () => {
  return (
    <>
        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">8. Governing Law</Typography>
            <Typography><strong>Jurisdiction:</strong> These Terms are governed by the laws of the state where Immigrateable is headquartered. Any disputes arising from these Terms will be resolved in the courts of that state.</Typography>
        </Typography>

        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">9. Changes to Terms</Typography>
            <Typography><strong>Modifications:</strong> We reserve the right to modify these Terms at any time. Changes will be posted on our website, and your continued use of our services constitutes acceptance of the modified Terms.</Typography>
        </Typography>

        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">10. Termination</Typography>
            <Typography><strong>Termination by You:</strong> You may terminate your account at any time by notifying us.</Typography>
            <Typography><strong>Termination by Us:</strong> We reserve the right to terminate or suspend your account and access to our services at our discretion, without notice, if you violate these Terms or for other reasons.</Typography>
        </Typography>

        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">11. Third-Party Links</Typography>
            <Typography>Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these external sites. Use of these sites is at your own risk.</Typography>
        </Typography>

        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">12. Entire Agreement</Typography>
            <Typography>These Terms, along with our Privacy Policy, constitute the entire agreement between you and Immigrateable regarding your use of our services.</Typography>
        </Typography>

        <Typography paragraph>
            <Typography variant="h6" fontWeight="bold" marginBottom="10px">13. Contact Information</Typography>
            <Typography>For any questions or concerns regarding these Terms, please contact us at <Link to="mailto:support@immigrateable.com"> support@immigrateable.com</Link></Typography>
        </Typography>
    </>
  )
}

export default Terms