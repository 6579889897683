import "./App.css";
import Hero from "./partials/Hero/Hero.tsx";
import HowItWorksSection from "./partials/HowItWorks/HowItWorksSection.tsx";
import Testimonials from "./partials/Testimonials/Testimonials.tsx";
import BeginJourney from "./partials/BeginJourney/BeginJourney.tsx";
import WhyChooseUs from "./partials/WhyChooseUs/WhyChooseUs.tsx";
import Pathway from "./partials/PathWay/PathWay.tsx";
import JoinUs from "./partials/JoinUs/JoinUs.tsx";
import Contactus from "./partials/ContactUs/ContactUs.tsx";
import Footer from "./partials/Footer/Footer.tsx";
import EmploymentStats from "./partials/EmploymentStats/EmploymentStats.tsx";

function App() {
  return (
    <>
      <Hero />
      <HowItWorksSection />
      <Testimonials />
      <BeginJourney />
      <WhyChooseUs />
      <EmploymentStats />
      <Pathway />
      <JoinUs />
      <Contactus />
      <Footer />
    </>
  );
}

export default App;
