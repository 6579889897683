import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, Grid, Link } from "@mui/material";
import "./_Logo.scss";

import logo from "../../assets/images/logo.png";

interface LogoProps {
  color: string;
}

export default function Logo({ color }: LogoProps) {
  return (
    <Box  id="navbar-logo" display="flex" flexDirection="row" alignItems="center">
      <Grid container display="flex">
        <Grid item display="flex" xs={12} sm="auto">
          <Link
           
            className="logo"
            flexGrow={{ xs: 12, md: "auto" }}
            display="flex"
            component={RouterLink}
            to="/"
            color={color}
            underline="none"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img width="250" src={logo} alt="Easy DV" />
          </Link>
        </Grid>
        <Grid item display={{ xs: "none", sm: "flex" }}>
          <Divider orientation="vertical" flexItem sx={{ mr: 2, ml: 2 }} />
        </Grid>
        {/* <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          xs={12}
          sm="auto"
          justifyContent="center"
        >
          <Typography
            variant="body1"
            noWrap
            textAlign="center"
            color={color}
            sx={{
              md: 12,
              display: "flex",
              fontWeight: 400,
              fontSize: "0.9rem",
              textDecoration: "none",
            }}
          >
            Life, Liberty &<br />
            the Pursuit of Happiness
          </Typography>
        </Grid>*/}
      </Grid>
    </Box>
  );
}
