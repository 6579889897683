import React, { ReactNode } from 'react';
import './Pricing.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

interface Feature {
  title: string;
  text: ReactNode;
  hasIt: boolean;
}

interface Plan {
  name: string;
  price: number;
  features: {
    [key: string]: Feature;
  };
}

const plans: Plan[] = [
  {
    name: 'Pro',
    price: 35,
    features: {
      subtitle1: {
        title: 'Year-Round Application Acceptance:',
        text: 'Submit your application any time of the year.',
        hasIt: true,
      },
      subtitle2: {
        title: 'Application Compliance Review:',
        text: 'We review your application to ensure it meets DV Lottery requirements.',
        hasIt: true,
      },
      subtitle3: {
        title: 'Photo Compliance Verification:',
        text: 'We verify and adjust your photos to meet standards.',
        hasIt: true,
      },
      subtitle4: {
        title: 'User Login Account:',
        text: 'Manage your application through a personalized online account.',
        hasIt: true,
      },
      subtitle5: {
        title: 'Free English Course:',
        text: 'Enjoy 30 days of free access to the English course.',
        hasIt: true,
      },
      subtitle6: {
        title: 'Unlimited Access/Changes:',
        text: 'Make unlimited changes to your application whenever needed.',
        hasIt: true,
      },
      subtitle7: {
        title: 'Correct and Timely Submission:',
        text: 'We ensure your application is submitted accurately and on time.',
        hasIt: true,
      },
      subtitle8: {
        title: 'Certificate of Participation:',
        text: 'We’ll send you the DV Lottery Entrant Number confirmation email.',
        hasIt: true,
      },
      subtitle9: {
        title: 'Winning Notifications:',
        text: 'We\'ll notify you if you win the DV Lottery.',
        hasIt: true,
      },
      subtitle10: {
        title: 'Re-apply Notifications:',
        text: 'We’ll email you reminders to re-apply if you\'re not selected.',
        hasIt: true,
      },
      subtitle11: {
        title: 'Automatic Re-application:',
        text: ( 
        <>
        If you're not selected, we will automatically reapply for the following year. <strong>(Bonus: $35 Value Free)</strong>
        </>
        ),
        hasIt: false,
      },
      subtitle12: {
        title: 'Interview Preparation:',
        text: 'We’ll help preparing for your Green Card interview.',
        hasIt: false,
      },
      subtitle13: {
        title: 'After Winning Assistance:',
        text: 'We assist you in completing the online DS-260 form.',
        hasIt: false,
      },
      subtitle14: {
        title: 'Flight Ticket Reimbursement:',
        text: 'You will be reimbursed up to $500 for your flight to the USA to activate your Green Card.',
        hasIt: false,
      },
    },
  },
  {
    name: 'Elite',
    price: 60,
    features: {
      subtitle1: {
        title: 'Year-Round Application Acceptance:',
        text: 'Submit your application any time of the year.',
        hasIt: true,
      },
      subtitle2: {
        title: 'Application Compliance Review:',
        text: 'We review your application to ensure it meets DV Lottery requirements.',
        hasIt: true,
      },
      subtitle3: {
        title: 'Photo Compliance Verification:',
        text: 'We verify and adjust your photos to meet standards.',
        hasIt: true,
      },
      subtitle4: {
        title: 'User Login Account:',
        text: 'Manage your application through a personalized online account.',
        hasIt: true,
      },
      subtitle5: {
        title: 'Free English Course:',
        text: 'Enjoy 30 days of free access to the English course.',
        hasIt: true,
      },
      subtitle6: {
        title: 'Unlimited Access/Changes:',
        text: 'Make unlimited changes to your application whenever needed.',
        hasIt: true,
      },
      subtitle7: {
        title: 'Correct and Timely Submission:',
        text: 'We ensure your application is submitted accurately and on time.',
        hasIt: true,
      },
      subtitle8: {
        title: 'Certificate of Participation:',
        text: 'We’ll send you the DV Lottery Entrant Number confirmation email.',
        hasIt: true,
      },
      subtitle9: {
        title: 'Winning Notifications:',
        text: 'We\'ll notify you if you win the DV Lottery.',
        hasIt: true,
      },
      subtitle10: {
        title: 'Re-apply Notifications:',
        text: 'We’ll email you reminders to re-apply if you\'re not selected.',
        hasIt: true,
      },
      subtitle11: {
        title: 'Automatic Re-application:',
        text: 'If you\'re not selected, we will automatically reapply for the following year.',
        hasIt: true,
      },
      subtitle12: {
        title: 'Interview Preparation:',
        text: 'We’ll help preparing for your Green Card interview.',
        hasIt: false,
      },
      subtitle13: {
        title: 'After Winning Assistance:',
        text: 'We assist you in completing the online DS-260 form.',
        hasIt: false,
      },
      subtitle14: {
        title: 'Flight Ticket Reimbursement:',
        text: 'You will be reimbursed up to $500 for your flight to the USA to activate your Green Card.',
        hasIt: false,
      },
    },
  },
  {
    name: 'VIP',
    price: 99,
    features: {
      subtitle1: {
        title: 'Year-Round Application Acceptance:',
        text: 'Submit your application any time of the year.',
        hasIt: true,
      },
      subtitle2: {
        title: 'Application Compliance Review:',
        text: 'We review your application to ensure it meets DV Lottery requirements.',
        hasIt: true,
      },
      subtitle3: {
        title: 'Photo Compliance Verification:',
        text: 'We verify and adjust your photos to meet standards.',
        hasIt: true,
      },
      subtitle4: {
        title: 'User Login Account:',
        text: 'Manage your application through a personalized online account.',
        hasIt: true,
      },
      subtitle5: {
        title: 'Free English Course:',
        text: 'Enjoy 30 days of free access to the English course.',
        hasIt: true,
      },
      subtitle6: {
        title: 'Unlimited Access/Changes:',
        text: 'Make unlimited changes to your application whenever needed.',
        hasIt: true,
      },
      subtitle7: {
        title: 'Correct and Timely Submission:',
        text: 'We ensure your application is submitted accurately and on time.',
        hasIt: true,
      },
      subtitle8: {
        title: 'Certificate of Participation:',
        text: 'We’ll send you the DV Lottery Entrant Number confirmation email.',
        hasIt: true,
      },
      subtitle9: {
        title: 'Winning Notifications:',
        text: 'We\'ll notify you if you win the DV Lottery.',
        hasIt: true,
      },
      subtitle10: {
        title: 'Re-apply Notifications:',
        text: 'We’ll email you reminders to re-apply if you\'re not selected.',
        hasIt: true,
      },
      subtitle11: {
        title: 'Automatic Re-application:',
        text: 'If you\'re not selected, we will automatically reapply for the following year.',
        hasIt: true,
      },
      subtitle12: {
        title: 'Interview Preparation:',
        text: 'We’ll help preparing for your Green Card interview.',
        hasIt: true,
      },
      subtitle13: {
        title: 'After Winning Assistance:',
        text: 'We assist you in completing the online DS-260 form.',
        hasIt: true,
      },
      subtitle14: {
        title: 'Flight Ticket Reimbursement:',
        text: 'You will be reimbursed up to $500 for your flight to the USA to activate your Green Card.',
        hasIt: true,
      },
    },
  },
];

const Pricing: React.FC = () => {
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" gutterBottom style={{fontWeight: 700, fontSize:"32px"}}>
                  Compare plans
                </Typography>
                <Typography variant="subtitle1" gutterBottom style={{color: "#858BA0"}}>
                  The stated plan prices include the preparation of a single DV Lottery application.
                </Typography>
              </TableCell>
              {plans.map((plan) => (
                <TableCell key={plan.name} align="center">
                  <Typography variant="h6" className='price'>${plan.price}</Typography>
                  <Typography variant="subtitle1" className='plan-name'>{plan.name}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(plans[0].features).map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row" className='features-tableCell'>
                  <span style={{fontWeight: 700}}>{plans[0].features[key].title}</span> {plans[0].features[key].text}
                </TableCell>
                {plans.map((plan) => (
                  <TableCell key={plan.name} align="center">
                    {plan.features[key].hasIt ? (
                      <VerifiedIcon />
                    ) : ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Pricing;
