import { Outlet } from "react-router-dom";
import MainHeader from "../partials/Header/MainHeader.tsx";
import { CssBaseline, Paper } from "@mui/material";
import "./_RootLayout.scss";

export default function Rootlayout() {
  return (
    <Paper className="wrapper">
      <CssBaseline />
      <MainHeader />
      <Outlet />
    </Paper>
  );
}
