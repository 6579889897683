import React from "react";

import FamilyDetails from "../FamilyDetails/FamilyDetails";
import Address from "../Address/Address.tsx";
import PersonalDetails from "../PersonalDetails/PersonalDetails.tsx";

const Review = ({ setAddress }: { setAddress: any }) => {
  

  return (
    <>
      <PersonalDetails showEdit={true} promptInfo={true} message=""/>
      <div style={{ marginTop: "40px" }} />
      <Address showEdit={true} promptInfo={true}/>
      <div style={{ marginTop: "50px", marginBottom: "20px" }} />
      <FamilyDetails showEdit={true} promptInfo={true}/>
    </>
  );
};

export default Review;
