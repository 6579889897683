// PassportInfoContext.tsx
import React, { createContext, useState, ReactNode } from "react";

export interface PassportInfo {
  passport_number: string;
  passport_expiration_date: string;
  passport_issuing_country: string;
  nationality: string;
  passport_issue_date: string;
}

interface PassportInfoContextType {
  passportInfo: PassportInfo;
  setPassportInfo: React.Dispatch<React.SetStateAction<PassportInfo>>;
}

const defaultPassportInfo: PassportInfo = {
  passport_number: "",
  passport_expiration_date: "",
  passport_issuing_country: "",
  nationality: "",
  passport_issue_date: "",
};

export const PassportInfoContext = createContext<PassportInfoContextType>({
  passportInfo: defaultPassportInfo,
  setPassportInfo: () => {},
});

export const PassportInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [passportInfo, setPassportInfo] =
    useState<PassportInfo>(defaultPassportInfo);

  return (
    <PassportInfoContext.Provider value={{ passportInfo, setPassportInfo }}>
      {children}
    </PassportInfoContext.Provider>
  );
};
