import { Box, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import Edit from "../../assets/images/edit.png";
import Idea from "../../assets/images/idea.png";
import List from "../../assets/images/list.png";
import Hourglass from "../../assets/images/hourglass.png";
import HowItWorksProcessImg from "../../assets/images/how-it-works.jpg"

export type HowItWorksProps = {};

export default function HowItWorks({ }: HowItWorksProps) {

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box component="section" maxWidth="xl" className="how-it-works">
      <Container
        sx={{
          padding: { xs: 2, sm: 3, md: 4 },
        }}
        maxWidth="lg"
      >
        <Typography variant="h4" sx={{ marginBottom: 4 }} fontWeight="bold">
          How it Works
        </Typography>
        <Grid
          container
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          textAlign={{ xs: "center", /*md: "left"*/ }}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{ width: "100%" }}
        >
          <Grid
            xs={12}
            // sm={6}
            // md={3}
            // lg={3}
            item
            display="flex"
            justifyContent="flex-start"

          >
            <Paper sx={{ p: 2 }} elevation={0} style={{display: 'flex', alignItems: 'center', flexDirection: isMediumScreen ? 'column' : 'row',}}>
              <Box style={{marginRight: isMediumScreen ? 0 : '20px',}} width={isMediumScreen ? "100%" : '140px'} flexShrink={0}>
                <img src={Edit} alt="Edit" width={100} />
              </Box>
              <Box style={{display: 'flex', flexDirection: "column", alignItems: isMediumScreen ? "center" : "flex-start"}}>
                <h3>Apply Anytime</h3>
                <ul style={{ listStyle: "none", padding: 0, textAlign: "justify" }}>
                  <li>
                    <b>Flexible Application:</b> Apply with us at any time of the year, offering you flexibility and convenience.
                  </li>
                  <li>
                    <b>Thorough Review:</b> We review your application for compliance with DV Lottery requirements, ensuring it meets all necessary criteria.
                  </li>
                  <li>
                    <b>Photo Compliance:</b> We check your photo to ensure it meets DV Lottery specifications. Many applicants are rejected due to photo issues without notification. We make sure your photo is compliant to avoid this common pitfall.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            // sm={6}
            // md={3}
            // lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0} style={{display: 'flex', alignItems: 'center', flexDirection: isMediumScreen ? 'column' : 'row',}}>
              <Box style={{marginRight: isMediumScreen ? 0 : '20px',}} width={isMediumScreen ? "100%" : '140px'} flexShrink={0}>
                <img src={Idea} alt="Idea" width={100} />
              </Box>
              <Box style={{display: 'flex', flexDirection: "column", alignItems: isMediumScreen ? "center" : "flex-start"}}>
                <h3>Timely Submission</h3>
                <ul style={{ listStyle: "none", padding: 0, textAlign: "justify" }}>
                  <li>
                    <b>Preparation & Submission: </b> We prepare your application and submit it when the DV Lottery officially starts, ensuring your entry is timely and accurate.
                  </li>
                  <li>
                    <b>Receive Confirmation: </b> After submission, we send you the DV Lottery submission confirmation number, providing you with proof of entry.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            // sm={6}
            // md={3}
            // lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0} style={{display: 'flex', alignItems: 'center', flexDirection: isMediumScreen ? 'column' : 'row',}}>
              <Box style={{marginRight: isMediumScreen ? 0 : '20px',}} width={isMediumScreen ? "100%" : '140px'} flexShrink={0}>
                <img src={List} alt="List" width={100} />
              </Box>
              <Box style={{display: 'flex', flexDirection: "column", alignItems: isMediumScreen ? "center" : "flex-start"}}>
                <h3>Post-Win Support</h3>
                <ul style={{ listStyle: "none", padding: 0, textAlign: "justify" }}>
                  <li>
                    <b>Control & Transparency: </b> Access your personal login portal to view and edit your application anytime.
                  </li>
                  <li>
                    <b>Post-Win Assistance: </b> If you win, we assist with the next steps, including preparation for the US embassy interview and document submissions, ensuring you are well-prepared.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            // sm={6}
            // md={3}
            // lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0} style={{display: 'flex', alignItems: 'center', flexDirection: isMediumScreen ? 'column' : 'row',}}>
              <Box style={{marginRight: isMediumScreen ? 0 : '20px',}} width={isMediumScreen ? "100%" : '140px'} flexShrink={0}>
                <img src={Hourglass} alt="hourglass" width={100} />
              </Box>
              <Box style={{display: 'flex', flexDirection: "column", alignItems: isMediumScreen ? "center" : "flex-start"}}>
                <h3>Automatic Re-Submission</h3>
                <ul style={{ listStyle: "none", padding: 0, textAlign: "justify" }}>
                  <li>
                    <b>Annual Re-Submission: </b> If you don’t win, we automatically resubmit your DV Lottery application for the next year’s drawing.
                  </li>
                  <li>
                    <b>Continuous Support: </b> We continue to resubmit your application every year until you win, providing ongoing support and increasing your chances of success.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box>
          <Container>
            <Box
              component="img"
              src={HowItWorksProcessImg}
              alt="Descriptive Alt Text"
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: '100%',
              }}
            />
          </Container>
        </Box>
      </Container>
    </Box>
  );
}
