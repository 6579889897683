import { Container, Grid, Typography } from "@mui/material";
import "./_WhyChooseUs.scss";
import { ImageRows } from "./ImageRows.tsx";

import ai_technology from "../../assets/images/ai-technology.jpg";
import transparency from "../../assets/images/transparency.jpg";
import experience from "../../assets/images/experience.jpg";
import flexibility from "../../assets/images/flexibility.jpg";

enum location {
  "left" = "left",
  "right" = "right",
}

const imageRows = [
  {
    id: 1,
    image: ai_technology,
    title: "Cutting-Edge A.I. Technology",
    description:
      "In an era where technology is paramount, we utilize the most advanced A.I. to ensure your application is accurate and compliant. <br />" +
      "Our state-of-the-art system adapts to the latest DV Lottery requirements, offering a seamless and efficient application process.",
    location: location.left,
  },
  {
    id: 2,
    image: experience,
    title: "Experience",
    description:
      "Our team comprises individuals who have successfully navigated the DV Lottery process.<br />" +
      "We understand the journey to guide you based on firsthand experience.",
    location: location.right,
  },
  {
    id: 3,
    image: flexibility,
    title: "Flexibility and Freedom",
    description:
      "Cancel Anytime Policy: We understand that circumstances change. <br />" +
      "That's why we offer a flexible subscription model. You can cancel your Subscription at any time without penalties or complicated processes.",
    location: location.left,
  },
  {
    id: 4,
    image: transparency,
    title: "Commitment to Transparency",
    description:
      "We are 100% transparent, ensuring you are fully informed about all aspects of our service. <br />" +
      "There are no hidden fees or unexpected charges.",
    location: location.right,
  },
];

export default function WhyChooseUs() {
  return (
    <Container
      className="why-choose-us"
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: { xs: 2, sm: 3, md: 4 },
      }}
      disableGutters
    >
      <Grid container spacing={2} maxWidth="md">
        <Grid
          item
          display="flex"
          xs={12}
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <Typography variant="h4" fontWeight="bold">
            Why Choose Us for Your DV Lottery Application?
          </Typography>
        </Grid>
        {imageRows.map((row) => (
          <ImageRows
            key={row.id}
            locationAttr={row.location}
            image={row.image}
            title={row.title}
            description={row.description}
          />
        ))}
      </Grid>
    </Container>
  );
}
