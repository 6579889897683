import { Box, Container, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EmploymentStats.css";
import TruckDriver from "../../assets/images/truck-driver.jpg";
import Electrician from "../../assets/images/electrician.jpg";
import Cashier from "../../assets/images/cashier.jpg";
import Babysitter from "../../assets/images/babysitter.jpg";

interface SlideProps {
  articleTitle: string;
  articleSalary: string;
  articleInfo: string;
  image: string;
}

const Slide: React.FC<SlideProps> = ({
  articleTitle,
  articleSalary,
  articleInfo,
  image,
}) => {
  const slideStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <article className="stats__article">
      <div
        className="stats__article-image article-img1"
        style={slideStyle}
      ></div>
      <div className="stats__article-body article">
        <h4 className="article__title">{articleTitle}</h4>
        <span className="article__salary">{articleSalary}</span>
        <p className="article__info">{articleInfo}</p>
      </div>
    </article>
  );
};

function EmploymentStats() {
  const articles = [
    {
      articleTitle: "Truck Driver",
      articleSalary: "84.000$",
      articleInfo: `It does not require knowledge of the English language because this profession is so demanding. You can start working as a truck driver and making $84,000 a year as soon as you get your driver's license.`,
      image: TruckDriver,
    },
    {
      articleTitle: "Electrician",
      articleSalary: "62.000$",
      articleInfo: `Electricians' work is highly valued in the United States.	For employment, you will need specialized education. Electricians make an average of $62,000 per year.`,
      image: Electrician,
    },
    {
      articleTitle: "Cashier",
      articleSalary: "40.000$",
      articleInfo: `It is an excellent profession for practicing English. In a supermarket, you will frequently encounter an immigrant of various nationalities working as a cashier. The average annual salary is $32,000.`,
      image: Cashier,
    },
    {
      articleTitle: "Babysitter",
      articleSalary: "45.000$",
      articleInfo: `Finding work as a babysitter or caregiver in the United States is not difficult. Knowledge of the English language is not always required. Individual salaries average $39,000.`,
      image: Babysitter,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box className="stats" component="section">
      <Container>
        <div className="stats__header">
          <p className="stats__header-text">
            EMPLOYMENT STATS
            <p className="stats__header-hidden">IN THE UNITED STATES</p>
          </p>
          <h3 className="stats__header-title">
            What is the average American
            <span className="_green">salary?</span>
          </h3>
        </div>
        <div className="stats__body">
          <Slider {...settings}>
            {articles.map((article) => (
              <Slide
                articleTitle={article.articleTitle}
                articleSalary={article.articleSalary}
                articleInfo={article.articleInfo}
                image={article.image}
              />
            ))}
          </Slider>
        </div>
      </Container>
    </Box>
  );
}

export default EmploymentStats;
