import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";


const WelcomeForm: React.FC = () => {
  const { confirm } = useAuth();
// Renamed from username to email
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError("Email is not provided");
      return;
    }

    if (!password) {
      setError("Please enter an password");
      return;
    }

    try {
      // Calls the login function from AuthContext with the email and password states
      await confirm(email, password);
      navigate("/applications");
    }
    catch (error: unknown) {
      if (error instanceof Error) {
        setError(error?.message);
      } else {
        setError("An unexpected error occurred");
      }
    }
   
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

         <>
        <Typography component="h1" mb={4} variant="h5">
        🎉 Congratulations! 🎉
        </Typography>
        <Typography component="h1" mb={4} variant="h5">
        You’re one step closer to your American Dream. Please create a secure password to continue.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Grid
            display='flex'
            width="300px"
          >
          <TextField
          style={{width: "100%"}}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />   </Grid>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        
          
          <Button

            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
        </>
       
        
      </Box>
    </Container>
  );
};

export default WelcomeForm;
