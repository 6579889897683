// PhotosContext.tsx
import React, { createContext, useState, ReactNode } from "react";

export interface Photos {
  applicant_photo: string;
}

interface PhotosContextType {
  photos: Photos;
  setPhotos: React.Dispatch<React.SetStateAction<Photos>>;
}

const defaultPhotos: Photos = {
  applicant_photo: "",
};

export const PhotosContext = createContext<PhotosContextType>({
  photos: defaultPhotos,
  setPhotos: () => {},
});

export const PhotosProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [photos, setPhotos] = useState<Photos>(defaultPhotos);

  return (
    <PhotosContext.Provider value={{ photos, setPhotos }}>
      {children}
    </PhotosContext.Provider>
  );
};
