import Slider from "react-slick";

import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import Alejandro_González from "../../assets/images/alejandro_gonzalez.jpg";
import Cecilia_Almeida from "../../assets/images/cecilia_almeida.jpg";
import Maria_Pereira from "../../assets/images/maria_pereira.jpg";

interface TestimonialData {
  name: string;
  img: string;
  review: string;
}

const data: TestimonialData[] = [
  {
    name: "Alejandro González",
    img: Alejandro_González,
    review: `"WOW! Immigrateable.com uses AI technology to make the complex green card process simple. Highly recommend for anyone seeking help."`,
  },
  {
    name: "Cecilia Almeida",
    img: Cecilia_Almeida,
    review: `"Thanks to Immigrateable.com, I now call the U.S. home. I applied with them and forgot about it. Until I got that “Congratulations” email from the DV lottery program.”`,
  },
  {
    name: "Maria Pereira",
    img: Maria_Pereira,
    review: `“Immigrateable.com exceeded my expectations. I didn’t have to do much at all. They got my information and automatically applied for me. WOW!”`,
  },
];

interface TestimonialSlideProps {
  name: string;
  img: string;
  review: string;
}

const TestimonialSlide: React.FC<TestimonialSlideProps> = ({
  name,
  img,
  review,
}) => {
  const slideStyle = {
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <div className="testimonial__slide" style={slideStyle}>
      <div className="testimonial__name">
        <span className="name">{name}</span>
      </div>
      <div className="testimonial__review">
        <p>{review}</p>
      </div>
    </div>
  );
};

const SampleNextArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "cornflowerblue",
        borderRadius: "100%",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "cornflowerblue",
        borderRadius: "100%",
      }}
      onClick={onClick}
    />
  );
};

const Testimonials: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 930,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      component="section"
      className="testimonial"
      sx={{ marginBottom: 10, marginTop: 10 }}
    >
      <Container maxWidth="xl" component="section">
        <Box className="testimonial__slider-wrapper">
          <div className="testimonial__header">
            <Typography variant="h4" sx={{ marginBottom: 2 }} fontWeight="bold">
              Winning Voices: Green Card Success
            </Typography>
            <Typography
              variant="h5"
              sx={{ marginBottom: 8 }}
              fontWeight={500}
              style={{ color: `#000000cc` }}
            >
              Hear from Those Who Have Already Won Their Green Card Lotteries
            </Typography>
          </div>
          <Slider {...settings}>
            {data.map((d) => (
              <TestimonialSlide
                key={d.name}
                name={d.name}
                img={d.img}
                review={d.review}
              />
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
