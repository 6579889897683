import { Button, Container, Grid } from "@mui/material";

import ContactForm from "../../assets/images/contact-us.jpg";

import "./_ContactUs.scss";
import { Link as RouterLink } from "react-router-dom";

export default function Contactus() {
  return (
    <Container>
      <Grid
        container
        display="flex"
        flexDirection="row"
        rowSpacing={4}
        columnSpacing={4}
        padding={6}
      >
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexDirection="column"
          alignContent="flex-start"
          textAlign="left"
          rowGap={2}
        >
          <h1 style={{ marginBottom: "10px" }}>Contact Us</h1>
          <p>
            For more information or assistance, feel free to contact our support
            team at support@easydv.com or call us at 1-800-555 - EASY.
          </p>
          <Button
            id="contact-learn-more"
            component={RouterLink}
            to="account/refundpolicy"
            sx={{
              borderRadius: 5,
              backgroundColor: "#C54B3A",
              px: 4,
              color: "white",
              "&:hover": {
                backgroundColor: "#083657",
              },
              maxWidth: "200px",
            }}
          >
            Learn more
          </Button>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="row">
          <img
            width="100%"
            src={ContactForm}
            style={{
              aspectRatio: "auto",
              objectFit: "contain",
              borderRadius: "10px",
            }}
            alt="Contact Us"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
