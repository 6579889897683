// EducationContext.tsx
import React, { createContext, useState, ReactNode } from "react";

export interface Education {
  highest_education_level: string;
  year_of_completion: string;
  field_of_study: string;
  current_job_title: string;
}

interface EducationContextType {
  education: Education;
  setEducation: React.Dispatch<React.SetStateAction<Education>>;
}

const defaultEducation: Education = {
  highest_education_level: "",
  year_of_completion: "",
  field_of_study: "",
  current_job_title: "",
};

export const EducationContext = createContext<EducationContextType>({
  education: defaultEducation,
  setEducation: () => {},
});

export const EducationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [education, setEducation] = useState<Education>(defaultEducation);

  return (
    <EducationContext.Provider value={{ education, setEducation }}>
      {children}
    </EducationContext.Provider>
  );
};
