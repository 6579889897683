// src/theme/palette.ts
import { PaletteOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  primary: {
    main: "#556cd6",
  },
  secondary: {
    main: "#19857b",
  },
  // ... other colors
};

export default palette;
