import React, { Fragment, useContext, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../components/Logo/Logo.tsx";
import "./_MainHeader.scss";
import { MenuItems } from "../MenuItems.tsx";
import { useAuth } from "../../context/AuthContext";
import { PersonalInfoContext } from "../../context/PersonalInfoContext.tsx";
import { ContactInfoContext } from "../../context/ContactInfoContext.tsx";
import { FamilyDetailsContext } from "../../context/FamilyDetailsContext.tsx";

export default function MainHeader() {
  const { personalInfo, setPersonalInfo, resetPersonalInfo } =
    useContext(PersonalInfoContext);
  const { contactInfo, setContactInfo, resetContactInfo } =
    useContext(ContactInfoContext);
  const { familyDetails, setFamilyDetails, resetFamilyDetails } =
    useContext(FamilyDetailsContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const { isAuthenticated, logout } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isHiddenMenu = useMediaQuery("(max-width: 1010px)");
  const [returnToApp, setReturnToApp] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickToApp = () => {
    setReturnToApp(true);
    navigate("/application/personal-details");
  };

  const handleClickReturn = () => {
    setReturnToApp(false);
    if (!localStorage.getItem("personalInfo")) {
      const newPath = '/application/personal-details';
      navigate(newPath);
    } else {
      navigate(`/application/${personalInfo.id}/personal-details`);
    }
  };

  const drawer = (
    <Box display="flex" sx={{ width: "100%" }} onClick={handleDrawerToggle}>
      <List sx={{ width: "100%" }}>
        {MenuItems.map((menuItem) => (
          <ListItemButton
            sx={{ width: "100%" }}
            component={RouterLink}
            to={menuItem.url}
            key={menuItem.url}
          >
            <ListItemText
              sx={{ color: "text.primary", textTransform: "none" }}
              primary={menuItem.name}
            />
          </ListItemButton>
        ))}
        {isMobile ? (
          isAuthenticated ? (
            <Fragment>
              <ListItemButton
                onClick={() => navigate("/applications")}
                className="action-button-red"
              >
                <ListItemText
                  sx={{ color: "text.primary", textTransform: "none" }}
                  primary="Applications"
                />
              </ListItemButton>
              <ListItemButton
                id="logout"
                onClick={() => {
                  logout();
                  navigate("account/login");
                }}
                className="action-button-red"
              >
                <ListItemText
                  sx={{ color: "text.primary", textTransform: "none" }}
                  primary="Logout"
                />
              </ListItemButton>
            </Fragment>
          ) : (
            <Fragment>
              <ListItemButton
                onClick={handleClickReturn}
                className="action-button-red"
              >
                <ListItemText
                  id="start-for-free-button-mobile"
                  sx={{ color: "text.primary", textTransform: "none" }}
                  primary={
                    returnToApp ? "Return To Application" : "Start The Application"
                  }
                />
              </ListItemButton>
              <ListItemButton
                id="login"
                onClick={() => navigate("account/login")}
                className="action-button-red"
              >
                <ListItemText
                  sx={{ color: "text.primary", textTransform: "none" }}
                  primary="Login"
                />
              </ListItemButton>
            </Fragment>
          )
        ) : (
          ""
        )}
      </List>
    </Box>
  );

  return (
    <Container maxWidth="xl" sx={{ flexGrow: 1, p: 2 }}>
      <AppBar
        position="static"
        sx={{
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar disableGutters>
          {isHiddenMenu && (
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              className="dark-blue-text"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            component={RouterLink}
            to="/"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Logo color="primary" />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {!isMobile && (
            <Fragment>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {MenuItems.map(
                  (menuItem) =>
                    (!isHiddenMenu ||
                      !["How it works", "Questions", "Pricing"].includes(
                        menuItem.name,
                      )) && (
                      <Button
                        key={menuItem.url}
                        component={RouterLink}
                        to={menuItem.url}
                        onClick={handleClickToApp}
                        className="dark-blue-text"
                      >
                        {menuItem.name}
                      </Button>
                    ),
                )}
              </Box>
              <Box display="flex" gap="10px">
                {isAuthenticated ? (
                  <Box display="flex" flexDirection="row" columnGap={2}>
                    <Button
                      onClick={() => {
                        navigate("/applications");
                      }}
                      sx={{
                        borderRadius: 5,
                        backgroundColor: "#C54B3A",
                        px: 4,
                        color: "white",
                        fontSize: {
                          xs: "0.75rem", // smaller font size for small screens
                          sm: "0.875rem", // medium font size for medium screens
                          md: "1rem", // larger font size for large screens
                        },
                        "&:hover": {
                          backgroundColor: "#083657",
                        },
                      }}
                    >
                      Applications
                    </Button>
                    <Button
                      id="loguot"
                      className="dark-blue-text"
                      onClick={() => {
                        logout();
                        resetPersonalInfo();
                        resetContactInfo();
                        resetFamilyDetails();
                        navigate("account/login");
                      }}
                      sx={{
                        borderRadius: 5,
                        backgroundColor: "#C54B3A",
                        px: 4,
                        color: "white",
                        fontSize: {
                          xs: "0.75rem", // smaller font size for small screens
                          sm: "0.875rem", // medium font size for medium screens
                          md: "1rem", // larger font size for large screens
                        },
                        "&:hover": {
                          backgroundColor: "#083657",
                        },
                      }}
                    >
                      Logout
                    </Button>
                  </Box>
                ) : (
                  <Button
                    className="dark-blue-text"
                    onClick={handleClickReturn}
                    id="start-for-free-button-header"
                    sx={{
                      borderRadius: 5,
                      backgroundColor: "#C54B3A",
                      px: 4,
                      color: "white",
                      fontSize: {
                        xs: "0.75rem", // smaller font size for small screens
                        sm: "0.875rem", // medium font size for medium screens
                        md: "1rem", // larger font size for large screens
                      },
                      "&:hover": {
                        backgroundColor: "#083657",
                      },
                    }}
                  >
                    {returnToApp ? "Return To Application" : "Start The Application"}
                  </Button>
                )}
                {!isAuthenticated && (
                  <Button
                    id="login"
                    className="dark-blue-text"
                    onClick={() => navigate("account/login")}
                    sx={{
                      borderRadius: 5,
                      backgroundColor: "#14aab5",
                      px: 4,
                      color: "white",
                      fontSize: {
                        xs: "0.75rem", // smaller font size for small screens
                        sm: "0.875rem", // medium font size for medium screens
                        md: "1rem", // larger font size for large screens
                      },
                      "&:hover": {
                        backgroundColor: "#083657",
                      },
                    }}
                  >
                    Login
                  </Button>
                )}
              </Box>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </Container>
  );
}
