import { Box, Container, Grid } from "@mui/material";

export type FooterProps = {};

export default function Footer({}: FooterProps) {
  return (
    <Box component="section" sx={{ backgroundColor: "#083657", color: "#fff" }}>
      <Container>
        <Grid container spacing={2} maxWidth="xl">
          <Grid item xs={12} sm={6} md={4}>
            <h3>Company</h3>
            <ul>
              <li>About Us</li>
              <li>Our Team</li>
              <li>Careers</li>
              <li>Blog</li>
              <li>Press</li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <h3>Support</h3>
            <ul>
              <li>Help Center</li>
              <li>Terms of Service</li>
              <li>Security</li>
              <li>Privacy Policy</li>
              <li>Report a Bug</li>
            </ul>
          </Grid>
        </Grid>
        <footer className="footer">
          <p>© {new Date().getFullYear()}, All rights reserved.</p>
        </footer>
      </Container>
    </Box>
  );
}
