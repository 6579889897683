import { Button, Container, Grid, Paper, Typography } from "@mui/material";

import "./_JoinUs.scss";
import { Link as RouterLink } from "react-router-dom";

export default function JoinUs() {
  return (
    <Container component="section" maxWidth="xl" disableGutters>
      <Grid
        container
        className="joinus-section"
        display="flex"
        alignContent="flex-end"
        flexDirection="row"
        justifyContent="flex-end"
        p={2}
        minHeight={{ lg: "600px", xs: 400 }}
      >
        <Grid item display="flex" flexDirection="row" alignContent="flex-end">
          <Paper
            elevation={2}
            sx={{ borderRadius: 4 }}
            className="joinus-section__paper"
          >
            <Grid
              container
              rowGap={2}
              display="flex"
              flexDirection="column"
              textAlign="left"
            >
              <Grid item>
                <Typography variant="h5">Join EasyDV Today</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  We handle the details, you dream big. Join today for
                  personalized assistance and a stress-free DV Lottery
                  experience.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  id="join-easy-apply-now"
                  className="dark-blue-text"
                  component={RouterLink}
                  to="/application/personal-details"
                  sx={{
                    borderRadius: 5,
                    backgroundColor: "#C54B3A",
                    px: 4,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#083657",
                    },
                  }}
                >
                  Apply now
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
