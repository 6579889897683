import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Edit from "../../assets/images/edit.png";
import Idea from "../../assets/images/idea.png";
import List from "../../assets/images/list.png";
import Hourglass from "../../assets/images/hourglass.png";

export type HowItWorksProps = {};

export default function HowItWorksSection({}: HowItWorksProps) {
  return (
    <Box component="section" maxWidth="xl" className="how-it-works">
      <Container
        sx={{
          padding: { xs: 2, sm: 3, md: 4 },
        }}
        maxWidth="lg"
      >
        <Typography variant="h4" sx={{ marginBottom: 4 }} fontWeight="bold">
          How it Works
        </Typography>
        <Grid
          container
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          textAlign={{ xs: "center", md: "left" }}
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{ width: "100%" }}
        >
          <Grid
            xs={12}
            sm={6}
            md={3}
            lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0}>
              <img src={Edit} alt="Edit" />
              <h3>One-Time Setup</h3>
              <p>
                Sign up with EasyDV and fill out your application details. Our
                intuitive form makes this process quick and straightforward.
              </p>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0}>
              <img src={Idea} alt="Idea" />
              <h3>AI-powered accuracy</h3>
              <p>
                Leveraging advanced A.I. technology, we meticulously review your
                application for accuracy and compliance with the latest DV
                Lottery requirements.
              </p>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0}>
              <img src={List} alt="List" />
              <h3>Annual Submission</h3>
              <p>
                Once you're set up, relax! We automatically resubmit your
                application yearly, increasing your chances without extra
                effort.
              </p>
            </Paper>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            lg={3}
            item
            display="flex"
            justifyContent="flex-start"
          >
            <Paper sx={{ p: 2 }} elevation={0}>
              <img src={Hourglass} alt="hourglass" />
              <h3>Real-time updates</h3>
              <p>
                Stay informed with automatic updates on your application status
                and any changes in the DV Lottery process.
              </p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
